import { environment } from "src/environments/environment";

export class ConfigurationModel {
    private businessCategory: String;
    private businessType: String;
    private delivery: DeliveryConfig;
    private deliveryException: DeliveryExceptionConfig;
    private notificationConfig: NotificationConfig;
    private order: OrderConfig;
    private printConfig: PrintConfig;

    constructor() {
        this.businessCategory = 'b2b';
        this.businessType = '';
        this.delivery = new DeliveryConfig();
        this.deliveryException = new DeliveryExceptionConfig();

        this.notificationConfig = new NotificationConfig();
        this.order = new OrderConfig();
        this.printConfig = new PrintConfig();
    }

    public get _businessCategory(): String {
        return this.businessCategory;
    }

    public set _businessCategory(businessCategory: String) {
        this.businessCategory = businessCategory;
    }

    public get _businessType(): String {
        return this.businessType;
    }

    public set _businessType(businessType: String) {
        this.businessType = businessType;
    }

    public get _delivery(): DeliveryConfig {
        return this.delivery;
    }

    public set _delivery(delivery: DeliveryConfig) {
        this.delivery = delivery;
    }

    public get _deliveryException(): DeliveryExceptionConfig {
        return this.deliveryException;
    }

    public set _deliveryException(deliveryException: DeliveryExceptionConfig) {
        this.deliveryException = deliveryException;
    }

    public get _notificationConfig(): NotificationConfig {
        return this.notificationConfig;
    }

    public set _notificationConfig(notificationConfig: NotificationConfig) {
        this.notificationConfig = notificationConfig;
    }

    public get _order(): OrderConfig {
        return this.order;
    }

    public set _order(order: OrderConfig) {
        this.order = order;
    }

    public get _printConfig(): PrintConfig {
        return this.printConfig;
    }

    public set _printConfig(printConfig: PrintConfig) {
        this.printConfig = printConfig;
    }

}

// export class DeliveryConfig {
//     private minCharge: Number;
//     private orderAmountThreshold: Number;
//     private provider: String;
//     private types: DeliveryTypes;

//     constructor() {
//         this.minCharge = null;
//         this.orderAmountThreshold = null;
//         this.provider = '';
//         this.types = new DeliveryTypes();
//     }

//     public get _minCharge(): Number {
//         return this.minCharge;
//     }

//     public set _minCharge(minCharge: Number) {
//         this.minCharge = minCharge;
//     }

//     public get _orderAmountThreshold(): Number {
//         return this.orderAmountThreshold;
//     }

//     public set _orderAmountThreshold(orderAmountThreshold: Number) {
//         this.orderAmountThreshold = orderAmountThreshold;
//     }

//     public get _provider(): String {
//         return this.provider;
//     }

//     public set _provider(provider: String) {
//         this.provider = provider;
//     }

//     public get _types(): DeliveryTypes {
//         return this.types;
//     }

//     public set _types(types: DeliveryTypes) {
//         this.types = types;
//     }
// }

// export class DeliveryTypes {
//     private onDemand: Boolean;
//     private scheduled: Boolean;

//     constructor() {
//         this.onDemand = false;
//         this.scheduled = false;
//     }

//     public get _onDemand(): Boolean {
//         return this.onDemand;
//     }

//     public set _onDemand(onDemand: Boolean
//     ) {
//         this.onDemand = onDemand;
//     }

//     public get _scheduled(): Boolean {
//         return this.scheduled;
//     }

//     public set _scheduled(scheduled: Boolean) {
//         this.scheduled = scheduled;
//     }
// }

export class NotificationConfig {
    private enableIVRCall: Boolean;
    private onClickOrderAccept: Boolean;
    private sendNotifications: Boolean;
    private soundUrl: any;

    constructor() {
        this.enableIVRCall = false;
        this.onClickOrderAccept = null;
        this.sendNotifications = false;
        this.soundUrl = environment.notificationSound;
    }

    public get _enableIVRCall(): Boolean {
        return this.enableIVRCall;
    }

    public set _enableIVRCall(enableIVRCall: Boolean) {
        this.enableIVRCall = enableIVRCall;
    }

    public get _onClickOrderAccept(): Boolean {
        return this.onClickOrderAccept;
    }

    public set _onClickOrderAccept(onClickOrderAccept: Boolean) {
        this.onClickOrderAccept = onClickOrderAccept;
    }

    public get _sendNotifications(): Boolean {
        return this.sendNotifications;
    }

    public set _sendNotifications(sendNotifications: Boolean) {
        this.sendNotifications = sendNotifications;
    }
}

export class OrderConfig {
    private acceptanceMode: String;
    private minOrderAmount: Number;
    private packingCharges: PackingCharges;
    private paymentMethods: PaymentMethods;

    constructor() {
        this.packingCharges = new PackingCharges();
        this.paymentMethods = new PaymentMethods();
        this.acceptanceMode = 'manual';
        this.minOrderAmount = 0;
    }

    public get _acceptanceMode(): String {
        return this.acceptanceMode;
    }

    public set _acceptanceMode(acceptanceMode: String) {
        this.acceptanceMode = acceptanceMode;
    }

    public get _minOrderAmount(): Number {
        return this.minOrderAmount;
    }

    public set _minOrderAmount(minOrderAmount: Number) {
        this.minOrderAmount = minOrderAmount;
    }

    public get _packingCharges(): PackingCharges {
        return this.packingCharges;
    }

    public set _packingCharges(packingCharges: PackingCharges) {
        this.packingCharges = packingCharges;
    }

    public get _paymentMethods(): PaymentMethods {
        return this.paymentMethods;
    }

    public set _paymentMethods(paymentMethods: PaymentMethods) {
        this.paymentMethods = paymentMethods;
    }
}
export class PackingCharges {
    private chargesPer: string;
    private on: String;

    constructor() {
        this.chargesPer = 'quantity';
        this.on = 'item';
    }

    public get _chargesPer(): string {
        return this.chargesPer;
    }

    public set _chargesPer(chargesPer: string) {
        this.chargesPer = chargesPer;
    }

    public get _on(): String {
        return this.on;
    }

    public set _on(on: String) {
        this.on = on;
    }
}

export class PaymentMethods {
    private cod: boolean;
    private online: boolean;
    private wallet: boolean;

    constructor() {
        this.cod = false;
        this.online = true;
        this.wallet = false;
    }

    public get _cod(): boolean {
        return this.cod;
    }

    public set _cod(cod: boolean) {
        this.cod = cod;
    }

    public get _online(): boolean {
        return this.online;
    }

    public set _online(online: boolean) {
        this.online = online;
    }

    public get _wallet(): boolean {
        return this.wallet;
    }

    public set _Wallet(wallet: boolean) {
        this.wallet = wallet;
    }
}
export class PrintConfig {
    private printKot: Boolean;

    constructor() {
        this.printKot = false;
    }

    public get _printKot(): Boolean {
        return this.printKot;
    }

    public set _printKot(printKot: Boolean) {
        this.printKot = printKot;
    }
}

export class DeliveryConfig {
    private provider: string; //
    private canSelfPickup: boolean; //
    private chargeTypeV: string;
    private minCharge: string;
    private orderAmountThreshold: string;
    private selectCurrency: string
    private scheduleOrders: string;
    private orderingSystem: boolean;
    private minimalCartValue: boolean;
    private slotConfig: SlotConfig;
    private types: TypesConfig;
    private shareConfig: ShareConfig;
    private deliveryMerchantId: string;
    constructor() {
        this.provider = 'market';
        this.canSelfPickup = false;
        this.chargeTypeV = 'cartValueLimit';
        this.minCharge = '';
        this.orderAmountThreshold = '';
        this.selectCurrency = '';
        this.scheduleOrders = '';
        this.orderingSystem = false;
        this.minimalCartValue = false;
        this.slotConfig = new SlotConfig();
        this.types = new TypesConfig();
        this.shareConfig = new ShareConfig();
        this.deliveryMerchantId = '';
    }

    public get _provider(): string {
        return this.provider;
    }
    public set _provider(provider: string) {
        this.provider = provider;
    }

    public get _canSelfPickup(): boolean {
        return this.canSelfPickup;
    }
    public set _canSelfPickup(canSelfPickup: boolean) {
        this.canSelfPickup = canSelfPickup;
    }
    public get _chargeTypeV(): string {
        return this.chargeTypeV;
    }
    public set _chargeTypeV(chargeTypeV: string) {
        this.chargeTypeV = chargeTypeV;
    }
    public get _minCharge(): string {
        return this.minCharge;
    }
    public set _minCharge(minCharge: string) {
        this.minCharge = minCharge;
    }
    public get _orderAmountThreshold(): string {
        return this.orderAmountThreshold;
    }
    public set _orderAmountThreshold(orderAmountThreshold: string) {
        this.orderAmountThreshold = orderAmountThreshold
    }
    public get _selectCurrency(): string {
        return this.selectCurrency;
    }
    public set _selectCurrency(selectCurrency: string) {
        this.selectCurrency = selectCurrency
    }
    public get _minimalCartValue(): boolean {
        return this.minimalCartValue;
    }
    public set _minimalCartValue(minimalCartValue: boolean) {
        this.minimalCartValue = minimalCartValue;
    }
    public get _scheduleOrders(): string {
        return this.scheduleOrders;
    }
    public set _scheduleOrders(scheduleOrders: string) {
        this.scheduleOrders = scheduleOrders;
    }
    public get _orderingSystem(): boolean {
        return this.orderingSystem;
    }
    public set _orderingSystem(orderingSystem: boolean) {
        this.orderingSystem = orderingSystem;
    }

    public get _slotConfig(): SlotConfig {
        return this.slotConfig;
    }
    public set _slotConfig(value: SlotConfig) {
        this.slotConfig = value;
    }


    public get _types(): TypesConfig {
        return this.types;
    }
    public set _types(value: TypesConfig) {
        this.types = value;
    }

    public get _shareConfig(): ShareConfig {
        return this.shareConfig;
    }
    public set _shareConfig(value: ShareConfig) {
        this.shareConfig = value;
    }

    public get _deliveryMerchantId(): string {
        return this.deliveryMerchantId;
    }
    public set _deliveryMerchantId(deliveryMerchantId: string) {
        this.deliveryMerchantId = deliveryMerchantId;
    }

}

export class DeliveryExceptionConfig {
    private exceptionValue: string
    private status: string;
    private exceptionType: string;

    constructor() {
        this.exceptionValue = "5000";
        this.status = "active";
        this.exceptionType = "distance";
    }
    public get _exceptionValue(): string {
        return this.exceptionValue;
    }
    public set _exceptionValue(exceptionValue: string) {
        this.exceptionValue = exceptionValue;
    }
    public get _status(): string {
        return this.status;
    }
    public set _status(status: string) {
        this.status = status;
    }
    public get _exceptionType(): string {
        return this.exceptionType;
    }
    public set _exceptionType(exceptionType: string) {
        this.exceptionType = exceptionType;
    }
}


export class SlotConfig {
    private maxOrdersPerSlot: number
    private advanceDays: number;
    private minDurationHr: number;

    constructor() {
        this.maxOrdersPerSlot = 10;
        this.advanceDays = 5;
        this.minDurationHr = 2;
    }

    public get _maxOrdersPerSlot(): number {
        return this.maxOrdersPerSlot;
    }
    public set _maxOrdersPerSlot(maxOrdersPerSlot: number) {
        this.maxOrdersPerSlot = maxOrdersPerSlot;
    }


    public get _advanceDays(): number {
        return this.advanceDays;
    }
    public set _advanceDays(value: number) {
        this.advanceDays = value;
    }

    public get _minDurationHr(): number {
        return this.minDurationHr;
    }
    public set _minDurationHr(value: number) {
        this.minDurationHr = value;
    }

}

export class TypesConfig {

    private onDemand: boolean;
    private scheduled: boolean;

    constructor() {
        this.onDemand = true;
        this.scheduled = false;
    }

    public get _onDemand(): boolean {
        return this.onDemand;
    }
    public set _onDemand(onDemand: boolean) {
        this.onDemand = onDemand;
    }
    public get _scheduled(): boolean {
        return this.scheduled;
    }
    public set _scheduled(scheduled: boolean) {
        this.scheduled = scheduled;
    }
}

export class ShareConfig {
    private method: string; //['cartValueLimit', 'share', 'byDistance'],
    private unit: string; //  enum: ['amount', 'percent','perKm'],
    private ranges: RangeConfig[];
    private customer: number;
    private partner: number;

    constructor() {
        this.method = 'cartValueLimit';
        this.unit = 'amount';
        this.ranges = [new RangeConfig()];
        this.customer = 0;
        this.partner = 0;
    }



    public get _method(): string {
        return this.method;
    }
    public set _method(value: string) {
        this.method = value;
    }

    public get _unit(): string {
        return this.unit;
    }
    public set _unit(value: string) {
        this.unit = value;
    }

    public get _ranges(): RangeConfig[] {
        return this.ranges;
    }
    public set _ranges(value: RangeConfig[]) {
        this.ranges = value;
    }

    public get _customer(): number {
        return this.customer;
    }
    public set _customer(value: number) {
        this.customer = value;
    }
    public get _partner(): number {
        return this.partner;
    }
    public set _partner(value: number) {
        this.partner = value;
    }

}

export class RangeConfig {
    private to: number;
    private from: number;
    private price: number;

    constructor() {
        this.to = 0;
        this.from = 0;
        this.price = 0;
    }

    public get _to(): number {
        return this.to;
    }
    public set _to(value: number) {
        this.to = value;
    }

    public get _from(): number {
        return this.from;
    }
    public set _from(value: number) {
        this.from = value;
    }

    public get _price(): number {
        return this.price;
    }
    public set _price(value: number) {
        this.price = value;
    }

}

export class CustPartConfig {
    private customer: number;
    private partner: number;

    constructor() {
        this.customer = 0;
        this.partner = 0;
    }


    public get _customer(): number {
        return this.customer;
    }

    public set _customer(value: number) {
        this.customer = value;
    }

    public get _partner(): number {
        return this.partner;
    }

    public set _partner(value: number) {
        this.partner = value;
    }

}
import { AppData } from './app-data.service';
import { Router, RouterStateSnapshot } from '@angular/router';
import { StorageService } from './../../core/services/storage.service';
import { Injectable } from '@angular/core';
import { OutletDetails } from 'src/app/module/outlet-setup/models/outlet-details.model';
import { OrderService } from 'src/app/module/orders/services/order.service';
import { IdsService } from './ids.service';
import { SocketService } from './socket.service';
import { AudioService } from './audio.service';
import { ApiRequestService } from './api-request.service';
import { Order } from 'src/app/module/orders/models/order.model';
@Injectable({
  providedIn: 'root'
})
export class AuthService {
  private state: RouterStateSnapshot;
  constructor(
    private storageService: StorageService,
    private idsService: IdsService,
    private router: Router,
    private orderService: OrderService,
    private socketService: SocketService,
    private audioService: AudioService,
    private apiRequestService: ApiRequestService,
  ) {
    this.state = router.routerState.snapshot;
    if (this.isAuthenticated()) {
      this.reInitializeAppData();
      AppData.token$.next(this.storageService.getItem('token'));
      AppData.isAuthenticated = true;
    } else {
      // console.log('inside initApp');
      this.initializeAppData();
    }

    this.getDeviceId();
  }

  getDeviceId() {
    const deviceId = this.storageService.getItem('deviceId');
    // console.log(deviceId);

    if (deviceId === null || deviceId === '') {
      AppData.deviceIdSub$.next(this.idsService.generate());
    }
  }

  isAuthenticated() {
    return AppData.isAuthenticated;
  }

  initializeAppData() {
    AppData.userDataSub$.next(null);
    AppData.outletListSub$.next(null);
    AppData.restDataSub$.next(null);
    AppData.token$.next(null);
    AppData.outletNameSub$.next(null);
    AppData.isAuthenticated = false;
  }

  reInitializeAppData() {
    // console.log('re-init called');
    AppData.token$.next(this.storageService.getItem('token'));
    AppData.userDataSub$.next(this.storageService.getItem('userData'));
    const obj = {
      changeIndex: true,
      outletList: this.storageService.getItem('outletList') ? this.storageService.getItem('outletList') : []
    };
    AppData.outletListSub$.next(obj);

    AppData.isAuthenticated = true;

    const currentUrlTree = this.router['location']['_platformLocation']['location']['pathname'];
    const routeItem = this.router['location']['_platformLocation']['location']['search']
    // console.log(currentUrlTree, routeItem);

    if (AppData.isAuthenticated) {
      if (currentUrlTree === '/login' || currentUrlTree === '/') {
        this.router.navigate(['/panel/getting-started']);
      } else {
        this.router.navigateByUrl(currentUrlTree + routeItem);
      }
    } else {
      this.router.navigate(['login']);
    }


  }

 

  logout() {
 
    
    this.orderService.ordersSubject$.next({ model: new Order(), modelKey: 'acceptanceWait' });;
    this.storageService.clearStorage();
    this.initializeAppData();
    this.orderService.orderListIndexSubject$.next(0);
    this.orderService.orderStatusListModelKeySubject$.next('acceptanceWait');
    this.orderService.orderFilterSubject$.next(null);
    AppData.selectedIndexSub$.next(-1);
    AppData.outletDetailsSub$.next(new OutletDetails());
    AppData.customerDetailsSub$.next(null);
    AppData.selectedOrderItemSub$.next([]);
    AppData.posOutletsSub$.next([]);
    AppData.posSelectedOutletSub$.next({ selectedOutletId: '', selectedOutletName: '' });
    // AppData.facebookUserDetailsSub$.next({ userId: '', userName: '' });
    AppData.businessTypeSub$.next('');
    AppData.outletIdSub$.next('');
    AppData.outletListSub$.next(null);
    this.apiRequestService.post('logout',{});
    this.router.navigate(['/login']);
    this.audioService.stopNotificationSound();
    this.socketService.closeSocket();
  }
}

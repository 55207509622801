import { BrowserModule } from '@angular/platform-browser';
import { APP_INITIALIZER, ErrorHandler, NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { NgbActiveModal, NgbModalModule } from '@ng-bootstrap/ng-bootstrap';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { ToastrModule } from 'ngx-toastr';
import { ApiRequestService, AppData, GlobalErrorHandler, ToastService, TokenInterceptor } from './shared';
import { NetworkInterceptor } from './shared/interceptor/network.interceptor';
import { StorageService } from './core/services/storage.service';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CommonService } from './shared/services/common.service';
import { DatePipe } from '@angular/common';
import { SocketService } from './shared/services/socket.service';
import { LoaderModule } from './shared/components/loader/loader.module';
import { appInitializer } from './shared/services/app.initializer';
import { TwoDigitDecimalDirective } from './shared/utils/directives/two-digit-directive/two-digit-decimal.directive';
import { RouteReuseStrategy } from '@angular/router';
import { CustomReusingStrategy } from './shared/utils/custom-reusing-strategy';
import { AuthService } from './shared/services/auth.service';
import { NoInternetModule } from './module/no-internet/no-internet.module';
import { ResponseInterceptor } from './shared/interceptor/response.interceptor';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AddonModalModule } from './module/menu-setup/components/item-creation/components/addon-modal/addon-modal.module';


@NgModule({
  declarations: [
    AppComponent,
  ],
  imports: [
    BrowserModule,
    NoInternetModule,
    AppRoutingModule,
    HttpClientModule,
    ReactiveFormsModule,
    FormsModule,
    BrowserAnimationsModule,
    ToastrModule.forRoot(),
    LoaderModule,
    AddonModalModule,
    NgbModalModule
    

  ],
  entryComponents: [],

  providers: [
    AuthService, 
    {
    provide: ErrorHandler,
    useClass: GlobalErrorHandler
  },
    AppData,
  { provide: APP_INITIALIZER, useFactory: appInitializer, multi: true },

  {
    provide: HTTP_INTERCEPTORS,
    useClass: TokenInterceptor,
    multi: true
  },
  {
    provide: HTTP_INTERCEPTORS,
    useClass: ResponseInterceptor,
    multi: true
  },
  {
    provide: HTTP_INTERCEPTORS,
    useClass: NetworkInterceptor,
    multi: true
  },
  // { provide: RouteReuseStrategy, useClass: CustomReusingStrategy, multi: true },
     
    ApiRequestService,
    ToastService,
    StorageService,
    NgbActiveModal,
    CommonService,
    DatePipe,
    SocketService,
  
    

  ],
  bootstrap: [AppComponent]
})
export class AppModule { }

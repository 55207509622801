import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { ApiRequestService, AppData } from 'src/app/shared';
import { Order } from '../models/order.model';

@Injectable({
  providedIn: 'root'
})
export class OrderService {

  orderObj = {};
  orderObjSubject$ = new BehaviorSubject({});

  orderModel = new Order();
  ordersSubject$ = new BehaviorSubject({ model: new Order(), modelKey: '' }); // it's contains all orders

  orderListIndex = 0;
  orderListIndexSubject$ = new BehaviorSubject(0); // it's contains order item index

  orderStatusListModelKey;
  orderStatusListModelKeySubject$ = new BehaviorSubject('acceptanceWait'); // it's contains order type model key

  changeStatusSubject$ = new BehaviorSubject(''); // it's contains order status
  orderFilterSubject$ = new BehaviorSubject(null);

  orderCancelFromSupport$ = new BehaviorSubject('');

  getNewOrderSubject$ = new BehaviorSubject(0);

  constructor(
    private apiService: ApiRequestService
  ) {
    this.ordersSubject$.subscribe((data) => {
      this.orderModel = Object.assign(this.orderModel, data.model);
    });

    this.orderObjSubject$.subscribe((data) => {
      if (Object.keys(this.orderObj).length > 0) {
        this.orderObj = Object.assign(this.orderObj, data);
      }
    });

    this.orderListIndexSubject$.subscribe((data) => {
      this.orderListIndex = data;
    });

    this.orderStatusListModelKeySubject$.subscribe((data) => {
      this.orderStatusListModelKey = data;
    });
  }

  // for set Order object
  setOrderObject(obj) {
    console.log(obj);
    this.orderObjSubject$.next(obj);
  }

  // for remove item from list
  removeItemFromOrderList(ind, modelKey) {
    this.orderModel[modelKey].splice(ind, 1);
    this.ordersSubject$.next({ model: this.orderModel, modelKey: modelKey });
  }


  filterOrderList(tempList, fieldName, searchStr) {
    return tempList.filter((inputObj) => {
      return inputObj[fieldName].toLowerCase().indexOf(searchStr) >= 0;
    });
  }
  // for change order status
  changeStatus(obj) {
    return this.apiService.put('changeStatus', '', obj);
  }
  // for get orders
  getOrders(orderType, orderPageLimit, pageSkip, sort) {
    return this.apiService.get('getOrders', '?status=' + orderType + '&sort=' + sort + '&skip=' + pageSkip + '&limit=' + orderPageLimit);
  }

  // for off menu item
  offMenuItems(obj) {
    return this.apiService.post('offMenuItem', obj);
  }

  // for outlet off
  setOnOff(reqData) {
    return this.apiService.put('setAvailability', '', reqData);
  }

  filterList(inputList, inputKey, inputValue) {
    return inputList.filter((inputObj) => {
      return inputObj[inputKey].toLowerCase().indexOf(inputValue) >= 0;
    });
  }

  orderItemClick(index = this.orderListIndex) {
    this.orderListIndexSubject$.next(index);
    // this.setOrderObject(this.orderModel[this.orderStatusListModelKey][index]);
  }

  orderDeliverInfo(orderId, outletId) {
    return this.apiService.get('deliveryInfo', '?orderId=' + orderId + '&outletId=' + outletId);
  }

  onClickOrderAcceptOnNotification(outletId) {
    const obj = AppData.outletList.find(item => item.outletId === outletId);
    if (obj['config']['notificationConfig']['onClickOrderAccept']) {
      return true;
    } else {
      return false;
    }
  }

  getSoundUrl(outletId) {
    const obj = AppData.outletList.find(item => item.outletId === outletId);
    return obj['config']['notificationConfig']['soundUrl'];
  }

}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NoInternetComponent } from './no-internet.component';
import { RouterModule, Routes } from '@angular/router';

const routes: Routes = [
    {
      path: 'no-internet',
      component: NoInternetComponent
    }
  ];
  

@NgModule({
    declarations: [NoInternetComponent],
    imports: [ CommonModule, RouterModule.forRoot(routes) ],
    exports: [],
    providers: [],
})
export class NoInternetModule {}
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AddonModalComponent } from './addon-modal.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RestrictFirstSpaceModule } from 'src/app/shared/utils/directives/restrict-first-space/restrict-first-space.module';
import { TextTrimModule } from 'src/app/shared/utils/directives/text-trim/text-trim.module';



@NgModule({
  declarations: [AddonModalComponent],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RestrictFirstSpaceModule,
    TextTrimModule
  ],
  entryComponents: [AddonModalComponent]
})
export class AddonModalModule { }

import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators, ValidatorFn } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { getInputFieldValidation } from 'src/app/shared/utils/common-functions';





@Component({
  selector: 'app-addon-modal',
  templateUrl: './addon-modal.component.html',
  styleUrls: ['./addon-modal.component.scss']
})
export class AddonModalComponent implements OnInit {
  @Input() modalObj;
  addonsForm: FormGroup;
  isSubmitted: boolean = false;
  numberPattern = "^[1-9][0-9]*$";
  changedControlName;
  constructor(private fb: FormBuilder, public activeModal: NgbActiveModal) { }

  ngOnInit() {
    this.initForm()
    if (this.modalObj.type === "VARIANT") {
      this.addonsForm.controls.min.setValue(1);
      this.addonsForm.controls.max.setValue(1);
      if (this.modalObj.addonName) {
        this.addonsForm.controls.addonName.setValue(this.modalObj.addonName, { emit: false });
        this.addonsForm.controls.displayName.setValue(this.modalObj.displayName, { emit: false });
      }
    } else {
      if (this.modalObj.addonName) {
        this.addonsForm.controls.addonName.setValue(this.modalObj.addonName, { emit: false });
        this.addonsForm.controls.displayName.setValue(this.modalObj.displayName, { emit: false });
        this.addonsForm.controls.min.setValue(this.modalObj.min, { emit: false });
        this.addonsForm.controls.max.setValue(this.modalObj.max, { emit: false });
      }
    }
  }

  minMaxValidator: any = (min: string, max: string, fg: FormGroup) => {
    const maxValueControl = fg.get(max);
    const maxValue = maxValueControl.value;
    const minimumValueControl = fg.get(min);
    const minimumValue = minimumValueControl.value;
    switch (this.changedControlName) {
      case max:
        if (maxValue && minimumValue && maxValue < minimumValue) {
          return { maxValueInvalid: true }
        }
        break;
      case min:
        if (minimumValue && maxValue && minimumValue > maxValue) {
          return { minValueInvalid: true }
        }
        break;
      default:
        return null;

    }
    return null;
  };


  initForm() {
    this.addonsForm = this.fb.group({
      addonName: ['', [Validators.required, Validators.maxLength(30)]],
      displayName: ['', [Validators.required, Validators.maxLength(30)]],
      min: ['', [Validators.required]],
      max: ['', [Validators.required, Validators.pattern(/^[1-9]\d*$/)]],
    }, { validators: this.minMaxValidator.bind(this, 'min', 'max') });


    // if (this.modalObj.type === 'VARIANT'){
    //   this.addonsForm.controls.displayName.disable();
    // }
    this.addonsForm.controls.min.valueChanges.subscribe(val => {
      this.changedControlName = 'min';
    });

    this.addonsForm.controls.max.valueChanges.subscribe(val => {
      this.changedControlName = 'max';
    });

  }

  onDirtyTouchInp(controlName: string) {
    return this.addonsForm.controls[controlName].touched || this.addonsForm.controls[controlName].dirty;
  }

  getValid(fieldName) {
    return getInputFieldValidation(this.addonsForm, fieldName, this.isSubmitted);
  }

  multipleSelect(event) {
    console.log(event);
  }

  onAddonFormSubmit() {
    this.isSubmitted = true;
    if (this.addonsForm.valid) {
      const addonObj = { ...this.modalObj, ...this.addonsForm.value }
      this.activeModal.close(addonObj);
    } else {
      return;
    }
  }
}

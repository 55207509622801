import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import { StorageService } from 'src/app/core/services/storage.service';
import { Order } from 'src/app/module/orders/models/order.model';
// import { OrderService } from 'src/app/module/orders/services/order.service';
import { OutletDetails } from 'src/app/module/outlet-setup/models/outlet-details.model';
import { ApiRequestService, AppData, AudioService } from '..';
import { IdsService } from './ids.service';
import { SocketService } from './socket.service';

@Injectable({
    providedIn: 'root'
})
export class LogoutService {

    ordersSubject$ = new BehaviorSubject({ model: new Order(), modelKey: '' }); // it's contains all orders
    orderModel = new Order();
    orderListIndexSubject$ = new BehaviorSubject(0); // it's contains order item index
    orderListIndex = 0;
    orderFilterSubject$ = new BehaviorSubject(null);
    orderStatusListModelKeySubject$ = new BehaviorSubject('acceptanceWait'); // it's contains order type model key
    orderStatusListModelKey;

    constructor(
    private storageService: StorageService,
    // private idsService: IdsService,
    private router: Router,
    // private orderService: OrderService,
    private socketService: SocketService,
    private audioService: AudioService,
    // private apiRequestService: ApiRequestService,
  ) {

    this.ordersSubject$.subscribe((data) => {
        this.orderModel = Object.assign(this.orderModel, data.model);
      });
  
      this.orderListIndexSubject$.subscribe((data) => {
        this.orderListIndex = data;
      });
  
      this.orderStatusListModelKeySubject$.subscribe((data) => {
        this.orderStatusListModelKey = data;
      });

  }

  orderItemClick(index = this.orderListIndex) {
    this.orderListIndexSubject$.next(index);
    // this.setOrderObject(this.orderModel[this.orderStatusListModelKey][index]);
  }
 

    initializeAppData() {
        AppData.userDataSub$.next(null);
        AppData.outletListSub$.next(null);
        AppData.restDataSub$.next(null);
        AppData.token$.next(null);
        AppData.outletNameSub$.next(null);
        AppData.isAuthenticated = false;
      }
    logout() {
        this.ordersSubject$.next({ model: new Order(), modelKey: 'acceptanceWait' });;
        this.storageService.clearStorage();
        this.initializeAppData();
        this.orderListIndexSubject$.next(0);
        this.orderStatusListModelKeySubject$.next('acceptanceWait');
        this.orderFilterSubject$.next(null);
        AppData.selectedIndexSub$.next(-1);
        AppData.outletDetailsSub$.next(new OutletDetails());
        AppData.customerDetailsSub$.next(null);
        AppData.selectedOrderItemSub$.next([]);
        AppData.posOutletsSub$.next([]);
        AppData.posSelectedOutletSub$.next({ selectedOutletId: '', selectedOutletName: '' });
        // AppData.facebookUserDetailsSub$.next({ userId: '', userName: '' });
        AppData.businessTypeSub$.next('');
        AppData.outletIdSub$.next('');
        AppData.outletListSub$.next(null);
        this.router.navigate(['/login']);
        this.audioService.stopNotificationSound();
        this.socketService.closeSocket();
      }
}
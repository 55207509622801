import { Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { Router } from '@angular/router';
import { ToastService } from '../services/toast.service';
import { ROUTES_STR } from 'src/app/core/constants/app-constants';

@Injectable(
    { providedIn: 'root' }
)
export class NetworkInterceptor implements HttpInterceptor {

    constructor(private router: Router,
        private toasterService: ToastService) { }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(req).pipe(tap((event: HttpEvent<any>) => {
            if (event instanceof HttpResponse) {
                if (!window.navigator.onLine) {
                    this.router.navigate([ROUTES_STR.noInternet], {queryParams: {'navigateFrom': window.location.pathname}});
                    return;
                }
            }
        }, (err: any) => {
            if (!window.navigator.onLine) {
                this.router.navigate([ROUTES_STR.noInternet], {queryParams: {'navigateFrom': window.location.pathname}});
                console.log("offline ",window.location.pathname)
                // this.toasterService.presentToast('Check internet connection', 'Error', 'error');
                return;
            } 
            console.log("offline ",window.location.pathname)
        }));
    }
}

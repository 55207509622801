import { TooltipOptions } from "ng2-tooltip-directive";
export const TUTORIAL_URL = "https://www.youtube-nocookie.com/embed/ZnuQXTqJTdM";
export const AVOID_SPACE = '^[a-zA-Z0-9]+(?:(?:\. |[’ ])[a-zA-Z0-9]+)*$';
export const REST_NAME_PATTERN = '^[a-zA-Z0-9]+(?:(?:\. |[’\' ])[a-zA-Z0-9]+)*$';
export const ONLY_CHARACTERS = '^[a-zA-Z]+(?:(?:\. |[’ ])[a-zA-Z]+)*$';
export const ONLY_CHAR = "^[a-zA-Z ]+$";
export const CHAR_WITH_NUMBER = "^[a-zA-Z0-9_ ]*$";
export const AVOID_SPACE_ONLY = "^[a-zA-Z0-9!-/:-@Z-`|]+(?:(?:\. |[' ])[a-zA-Z0-9!-/:-@Z-`|]+)*$";
export const ONLY_CHARACTERS_WITH_AND_DOT = '^[a-zA-Z]+(?:(?:\. |[’ ])[.&a-zA-Z.&]+)*$';
export const EMAIL_PATTERN = /^(([^<>!#$%&\^\*()\[\]\\.,;:\s@'"?`~]+(\.[^<>()\[\]\\.,;:\s@']+)*)|('.+'))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
// export const DEVICE_ID = 'web';
// export const GOOGLE_API_KEY = 'AIzaSyCxhBrN_GJqqrfeX7URiJvVAWsQOpMVFOk';
export const INTERVAL_TIME_ONE_MIN = 1000 * 60;
export const COUNTER = 2;
export const SAMPLE_MENU_ITEM_UPLOAD_EXCEL = 'https://sgp1.digitaloceanspaces.com/listing/restaurant-dashboard-v2/common_document/Menu_Upload_Sample_v2.xlsx';
// export const COUNTRY_CODE = '+91';
export const NEW_COUNTRY_CODES = [{ code: '+91' }, { code: '+1' }];
export const OUTLET_FORM_STATUS = 'complete';
export const GOOGLE_API_KEY = 'AIzaSyCxhBrN_GJqqrfeX7URiJvVAWsQOpMVFOk';
export const ON_CALL_HEADER = { 'x-app-type': 'on-call' };
export const DEFAULT_COLORS = {
    primaryColor: '#213454',
    secondaryColor: '#E3EDFD'
}

export const APP_DETAILS = {
    // 'deviceId': DEVICE_ID,
    'appVersion': '1.0.0',
    'app': 'restaurant_panel',
};


export const EMPTY_PLATE = {
    YES: 'YES',
    NO: 'NO'
}

export const ORDER_PAGE_LIMIT = 50;
export const SECONDS = 59;
export const seconds = SECONDS;

export const AllowedExcelFormat = [
    'xlsx',
    'xls',
    'application/vnd.ms-excel',
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
];
export const PROFILE_MENU_LIST = [
    { name: 'Basic Details', isCompleted: false },
    { name: 'Operational timings', isCompleted: false },
    { name: 'Billing / Taxes', isCompleted: false },
    { name: 'FSSAI', isCompleted: false },
    { name: 'Bank Details', isCompleted: false },
    {
        name: 'Configuration', isCompleted: null,
        subMenu: [
            // { key: 'Theme Config' }, { key: 'Item Layout' },, { key: 'Payment' }
            { key: 'Delivery' }, { key: 'Order' }]
    },
];
export const SOCIAL_MENU_LIST = [
    { name: 'Campaign Type', isCompleted: false },
    { name: 'Budget', isCompleted: false },
    { name: 'Audience', isCompleted: false },
    { name: 'Ad Post', isCompleted: false },
];
export const SETTING_MENU_LIST = [
    { name: 'Theme Config', isCompleted: false },
    // { name: 'Item Layout', isCompleted: false },
];

//  Logic for Passing the percentage for the Left arrow for Modal 
// Length of Sub Menu # Percentage
// 2 #	66%
// 3 #	44%
// 4 #	33%

// name: 'Setup', permName: 'settings', icon: 'icon-setting', arrow: 'icon-right-arrow', url: '/panel/profile',
// subMenu: [{ option: 'Create new outlet' }, { option: 'Delivery Marketplace' }],
// modalClass: 'SIDEBAR_TOOLTIP_MODAL'

export const SIDE_MENU_ITEMS = [
    { name: 'Dashboard', permName: 'order', icon: 'icon-rocket', url: '/panel/getting-started', displayInMobile: true, subMenu: [] },
    { name: 'Orders', permName: 'order', icon: 'icon-order', url: '/panel/orders', displayInMobile: false, subMenu: [] },
    {
        name: 'Products', permName: 'menu', icon: 'icon-menu', url: '/panel/products', displayInMobile: false, collapsed: true,
        subMenu: [
            { name: 'Main Menu', icon: 'icon-main-menu', url: '/panel/products/all-items' },
            { name: 'Categories', icon: 'icon-categories', url: '/panel/products/categories' },
            { name: 'Item Import', icon: 'icon-file-import-fill', url: '/panel/products/item-import' },
        ],
    },
    { name: 'Setup', permName: 'profile', icon: 'icon-setting', url: '/panel/profile', displayInMobile: false, subMenu: [] },
    { name: 'CRM', permName: 'customers', icon: 'icon-manage-users', url: '/panel/manage-customers', displayInMobile: false, subMenu: [] },
    { name: 'Offer', permName: 'customers', icon: 'icon-offers', url: '/panel/engagement/discounts', displayInMobile: false, subMenu: [] },
    // {
    //     name: 'Marketing', permName: 'profile', icon: 'icon-market-place', url: '/panel/engagement', displayInMobile: false, collapsed: true,
    //     subMenu: [
    //         { name: 'Offer', icon: 'icon-offers', url: '/panel/engagement/discounts' },
    //         { name: 'Channel', icon: 'icon-channel', url: '/panel/engagement/omni-channel' },
    //         { name: 'Social', icon: 'icon-social', url: '/panel/engagement/socials' },
    //         { name: 'Scratch card', icon: 'icon-insert-picture', url: '/panel/engagement/referrals' },
    //         { name: 'Banners', icon: 'icon-insert-picture', url: '/panel/engagement/banners' }
    //     ],
    // },
    { name: 'On-call Ordering', permName: 'profile', icon: 'icon-order', url: '/panel/new-order', displayInMobile: false, subMenu: [] },
    {
        name: "Report", permName: "report", icon: "icon-statistics", url: "/panel/report", displayInMobile: false, subMenu: []
    }
    // { name: 'Reports', permName: 'report', icon: 'icon-report', url: '/panel/report', displayInMobile: false, subMenu: [] },
];

export const ROUTES_STR = {
    // Account Related
    login: '/login',
    signUp: '/signup',
    account: '/panel/merchant',

    // Products
    allItem: '/panel/products/all-items',
    categories: '/panel/products/categories',
    itemImport: '/panel/products/item-import',
    newItem: '/panel/products/new-item',

    // Engagement
    offer: '/panel/engagement/discounts',
    createOffer: '/panel/engagement/create-discount',
    manageDiscounts: '/panel/engagement/manage-discounts',
    customiseReferral: '/panel/engagement/customise-referral',
    referral: '/panel/engagement/referrals',
    omniChannel: '/panel/engagement/omni-channel',
    social: '/panel/engagement/socials',
    banners: '/panel/engagement/banners',
    createBanners: '/panel/engagement/create-banner',
    createCampaign: '/panel/engagement/create-campaign',
    createPost: '/panel/engagement/create-post',
    managePost: '/panel/engagement/manage-post',
    manageCampaigns: '/panel/engagement/manage-campaigns',
    orders: '/panel/orders',
    outletSetup: '/panel/profile',
    support: '/panel/support',
    report: '/panel/report',
    setup: '/panel/setup',
    theme: '/panel/themes',
    dashboard: '/panel/dashboard',
    manageUsers: '/panel/manage-users',
    marketPlace: '/panel/market-place',
    settings: '/panel/settings',
    gettingStarted: '/panel/getting-started',
    mobilePanel: 'mobile-panel',

    // Error
    noInternet: '/no-internet',
}

export const DefaultTooltipOptions: TooltipOptions = {
    'placement': 'bottom',
    'show-delay': 500,
    'theme': 'light',
    'width': 400,
    'max-width': 400,
    'z-index': 99999999999,
    'pointerEvents': 'auto',
}

export const DAYS_FULL = [
    'sunday',
    'monday',
    'tuesday',
    'wednedsday',
    'thursday',
    'friday',
    'saturday'
];
export const DAYS_HALF = [
    'sun',
    'mon',
    'tue',
    'wed',
    'thu',
    'fri',
    'sat'
];

export const SLOT_TIME = [
    { key: "0000", value: "12:00 AM" }, { key: "0030", value: "12:30 AM" }, { key: "0100", value: "01:00 AM" },
    { key: "0130", value: "01:30 AM" }, { key: "0200", value: "02:00 AM" }, { key: "0230", value: "02:30 AM" },
    { key: "0300", value: "03:00 AM" }, { key: "0330", value: "03:30 AM" }, { key: "0400", value: "04:00 AM" },
    { key: "0430", value: "04:30 AM" }, { key: "0500", value: "05:00 AM" }, { key: "0530", value: "05:30 AM" },
    { key: "0600", value: "06:00 AM" }, { key: "0630", value: "06:30 AM" }, { key: "0700", value: "07:00 AM" },
    { key: "0730", value: "07:30 AM" }, { key: "0800", value: "08:00 AM" }, { key: "0830", value: "08:30 AM" },
    { key: "0900", value: "09:00 AM" }, { key: "0930", value: "09:30 AM" }, { key: "1000", value: "10:00 AM" },
    { key: "1030", value: "10:30 AM" }, { key: "1100", value: "11:00 AM" }, { key: "1130", value: "11:30 AM" },
    { key: "1200", value: "12:00 PM" }, { key: "1230", value: "12:30 PM" }, { key: "1300", value: "1:00 PM" },
    { key: "1330", value: "1:30 PM" }, { key: "1400", value: "2:00 PM" }, { key: "1430", value: "2:30 PM" },
    { key: "1500", value: "3:00 PM" }, { key: "1530", value: "3:30 PM" }, { key: "1600", value: "4:00 PM" },
    { key: "1630", value: "4:30 PM" }, { key: "1700", value: "5:00 PM" }, { key: "1730", value: "5:30 PM" },
    { key: "1800", value: "6:00 PM" }, { key: "1830", value: "6:30 PM" }, { key: "1900", value: "7:00 PM" },
    { key: "1930", value: "7:30 PM" }, { key: "2000", value: "8:00 PM" }, { key: "2030", value: "8:30 PM" },
    { key: "2100", value: "9:00 PM" }, { key: "2130", value: "9:30 PM" }, { key: "2200", value: "10:00 PM" },
    { key: "2230", value: "10:30 PM" }, { key: "2300", value: "11:00 PM" }, { key: "2330", value: "11:30 PM" },
    { key: "2359", value: "11:59 PM" },
];


export const ORDER_STATUS_TYPE = {
    'food': [
        { id: 1, name: 'New Orders', type: 'NEW_ORDERS', class: 'new-order', modelKey: 'acceptanceWait' },
        { id: 2, name: 'Preparing', type: 'PREPARING', class: 'preparing', modelKey: 'foodPreparation' },
        { id: 3, name: 'Food Ready', type: 'FOOD_READY', class: 'food-ready', modelKey: 'foodReady' },
        { id: 4, name: 'Completed', type: 'COMPLETED', class: 'completed', modelKey: 'outForDelivery' },
        { id: 5, name: 'Past Orders', type: 'PAST_ORDERS', class: 'past-orders', modelKey: 'pastOrders' },
    ],
    'grocery': [
        { id: 1, name: 'New Orders', type: 'NEW_ORDERS', class: 'new-order', modelKey: 'acceptanceWait' },
        { id: 2, name: 'In-Progress', type: 'PREPARING', class: 'preparing', modelKey: 'foodPreparation' },
        { id: 3, name: 'Out For Delivery', type: 'FOOD_READY', class: 'food-ready', modelKey: 'foodReady' },
        { id: 4, name: 'Completed', type: 'COMPLETED', class: 'completed', modelKey: 'outForDelivery' },
        { id: 5, name: 'Past Orders', type: 'PAST_ORDERS', class: 'past-orders', modelKey: 'pastOrders' },
    ],
    'stationary': [
        { id: 1, name: 'New Orders', type: 'NEW_ORDERS', class: 'new-order', modelKey: 'acceptanceWait' },
        { id: 2, name: 'In-Progress', type: 'PREPARING', class: 'preparing', modelKey: 'foodPreparation' },
        { id: 3, name: 'Out For Delivery', type: 'FOOD_READY', class: 'food-ready', modelKey: 'foodReady' },
        { id: 4, name: 'Completed', type: 'COMPLETED', class: 'completed', modelKey: 'outForDelivery' },
        { id: 5, name: 'Past Orders', type: 'PAST_ORDERS', class: 'past-orders', modelKey: 'pastOrders' },
    ],
    'fruits&veggies': [
        { id: 1, name: 'New Orders', type: 'NEW_ORDERS', class: 'new-order', modelKey: 'acceptanceWait' },
        { id: 2, name: 'In-Progress', type: 'PREPARING', class: 'preparing', modelKey: 'foodPreparation' },
        { id: 3, name: 'Out For Delivery', type: 'FOOD_READY', class: 'food-ready', modelKey: 'foodReady' },
        { id: 4, name: 'Completed', type: 'COMPLETED', class: 'completed', modelKey: 'outForDelivery' },
        { id: 5, name: 'Past Orders', type: 'PAST_ORDERS', class: 'past-orders', modelKey: 'pastOrders' },
    ],
    'meat&poultry': [
        { id: 1, name: 'New Orders', type: 'NEW_ORDERS', class: 'new-order', modelKey: 'acceptanceWait' },
        { id: 2, name: 'Preparing', type: 'PREPARING', class: 'preparing', modelKey: 'foodPreparation' },
        { id: 3, name: 'Food Ready', type: 'FOOD_READY', class: 'food-ready', modelKey: 'foodReady' },
        { id: 4, name: 'Completed', type: 'COMPLETED', class: 'completed', modelKey: 'outForDelivery' },
        { id: 5, name: 'Past Orders', type: 'PAST_ORDERS', class: 'past-orders', modelKey: 'pastOrders' },
    ],
    'bakery': [
        { id: 1, name: 'New Orders', type: 'NEW_ORDERS', class: 'new-order', modelKey: 'acceptanceWait' },
        { id: 2, name: 'Preparing', type: 'PREPARING', class: 'preparing', modelKey: 'foodPreparation' },
        { id: 3, name: 'Food Ready', type: 'FOOD_READY', class: 'food-ready', modelKey: 'foodReady' },
        { id: 4, name: 'Completed', type: 'COMPLETED', class: 'completed', modelKey: 'outForDelivery' },
        { id: 5, name: 'Past Orders', type: 'PAST_ORDERS', class: 'past-orders', modelKey: 'pastOrders' },
    ],
    'others': [
        { id: 1, name: 'New Orders', type: 'NEW_ORDERS', class: 'new-order', modelKey: 'acceptanceWait' },
        { id: 2, name: 'In-Progress', type: 'PREPARING', class: 'preparing', modelKey: 'foodPreparation' },
        { id: 3, name: 'Out For Delivery', type: 'FOOD_READY', class: 'food-ready', modelKey: 'foodReady' },
        { id: 4, name: 'Completed', type: 'COMPLETED', class: 'completed', modelKey: 'outForDelivery' },
        { id: 5, name: 'Past Orders', type: 'PAST_ORDERS', class: 'past-orders', modelKey: 'pastOrders' },
    ],
    '': [
        { id: 1, name: 'New Orders', type: 'NEW_ORDERS', class: 'new-order', modelKey: 'acceptanceWait' },
        { id: 2, name: 'In-Progress', type: 'PREPARING', class: 'preparing', modelKey: 'foodPreparation' },
        { id: 3, name: 'Out For Delivery', type: 'FOOD_READY', class: 'food-ready', modelKey: 'foodReady' },
        { id: 4, name: 'Completed', type: 'COMPLETED', class: 'completed', modelKey: 'outForDelivery' },
        { id: 5, name: 'Past Orders', type: 'PAST_ORDERS', class: 'past-orders', modelKey: 'pastOrders' },
    ]
};
export const ORDER_STATUS_BTN_TEXT = {
    'food': {
        'acceptanceWait': 'ACCEPT ORDER',
        'foodPreparation': 'FOOD READY',
        'foodReady': 'COMPLETE ORDER',
    },
    'grocery': {
        'acceptanceWait': 'ACCEPT',
        'foodPreparation': 'OUT FOR DELIVERY',
        'foodReady': 'MARK DELIVERED',
    },
    'stationary': {
        'acceptanceWait': 'ACCEPT',
        'foodPreparation': 'OUT FOR DELIVERY',
        'foodReady': 'MARK DELIVERED',
    },

    'fruits&veggies': {
        'acceptanceWait': 'ACCEPT',
        'foodPreparation': 'OUT FOR DELIVERY',
        'foodReady': 'MARK DELIVERED',
    },
    'meat&poultry': {
        'acceptanceWait': 'ACCEPT ORDER',
        'foodPreparation': 'FOOD READY',
        'foodReady': 'COMPLETE ORDER',
    },
    'bakery': {
        'acceptanceWait': 'ACCEPT ORDER',
        'foodPreparation': 'FOOD READY',
        'foodReady': 'COMPLETE ORDER',
    },
    'others': {
        'acceptanceWait': 'ACCEPT',
        'foodPreparation': 'OUT FOR DELIVERY',
        'foodReady': 'MARK DELIVERED',
    }

}

export const ORDER_STATUS = {
    acceptanceWait: { keys: 'acceptanceWait,valetOnWayToConfirm', sort: 1 },
    foodPreparation: { keys: 'foodPreparation', sort: 1 },
    foodReady: { keys: 'foodReady,waitingDeliveryPickup', sort: 1 },
    outForDelivery: { keys: 'outForDelivery,delivered,complete', sort: -1 },
    pastOrders: { keys: 'outForDelivery,delivered,complete,cancelled,rejected,inDispute,enforcedCancel', sort: -1 }
};

export const ALLOWED_LOGO_TYPE = [
    'image/png',
    'image/PNG',
    'image/jpg',
    'image/JPG',
    'image/jpeg',
    'image/JPEG',
];
export const ALLOWED_CERTIFICATE_TYPE = [
    'image/png',
    'image/PNG',
    'image/jpg',
    'image/JPG',
    'image/jpeg',
    'image/JPEG',
    'application/pdf',
];
export const UPLOAD_FILE_BEAN = {
    bytes: null,
    contentType: null,
    documentId: null,
    fileType: null,
    name: null,
    size: null
}
export const CLASS_COLOR_MAP = {
    'revenue-generated': '#28a745',
    'tax-component': '#663399',
    'revenue-value': '#dc3545',
    'avg-order-value': '#ffc107',
    'completed-order': '#28a745',
    'cancelled-orders': '#dc3545',
    'total-orders': '#3d2643',
    'food-ready': '#ffc107',
    'customer-reordered': '#663399',
    'new-customer': '#28a745',
};

export const ADDRESS_BY_CITY = {
    bounds: null,
    types: ['establishment'],
    fields: ["address_components", "geometry", "formatted_address", "name"],
    componentRestrictions: { country: ['in', 'us'] }
}

export type MODAL_TYPE = 'CONFIRM_MODAL_CONFIG' | 'COMMON_CSS_MODAL_CONFIG' | 'COMMON_CSS_STATIC_MODAL_CONFIG' | 'START_IMPORT_MODAL_CONFIG' | 'IMPORT_PREVIEW_MODAL_CONFIG' | 'SIGNOUT_FB_MODAL_CONFIG' | 'CREATE_TIMINGS_MODAL' |
    'FROM_RIGHT_MODAL' | 'FROM_TOP_MODAL' | 'FROM_TOP_MODAL2' | 'PROFILE_TOP_MODAL' | 'DELIVERY_EXCUTIVE_MODAL' |
    'ORDER_LIST_FILTER_MODAL' | 'PRINT_INVOICE_MODAL' | 'SIDEBAR_TOOLTIP_MODAL' | 'PAYMENT_MODAL' |
    'ORDER_FILTER_MODAL' | 'FILE_UPLOAD_MODAL' | 'PROD_COLLECTION_MODAL' | 'COLOR_PALETTE_CSS_MODAL_CONFIG' | 'VIDEO_PLAYER_MODAL' |
    'ORDER_TRACKING_MODAL_CONFIG' | 'ORDER_REJECT_MODAL' | 'SMALL_MODAL';

export function getConfig(type: MODAL_TYPE): any {
    const CONFIG = {
        CONFIRM_MODAL_CONFIG: {
            windowClass: 'modal fade come-from-modal',
            keyboard: false,
            scrollable: false,
            centered: true
        },
        COLOR_PALETTE_CSS_MODAL_CONFIG: {
            windowClass: 'color-palette-modal-css',
            // backdrop: 'static',
            keyboard: false,
            scrollable: false,
            centered: true
        },
        COMMON_CSS_MODAL_CONFIG: {
            windowClass: 'common-modal-css',
            // backdrop: 'static',
            keyboard: false,
            scrollable: false,
            centered: true
        },
        START_IMPORT_MODAL_CONFIG: {
            windowClass: 'start-import-modal-css',
            // backdrop: 'static',
            keyboard: false,
            scrollable: false,
            centered: true
        },

        CRM_IMPORT_MODAL_CONFIG: {
            windowClass: 'start-import-modal-css',
            // backdrop: 'static',
            keyboard: false,
            scrollable: false,
            centered: true
        },
        COMMON_CSS_STATIC_MODAL_CONFIG: {
            windowClass: 'common-modal-css',
            backdrop: 'static',
            keyboard: false,
            scrollable: false,
            centered: true
        },
        IMPORT_PREVIEW_MODAL_CONFIG: {
            windowClass: 'import-preview-modal-css',
            // backdrop: 'static',
            keyboard: false,
            scrollable: false,
            centered: true
        },
        SIGNOUT_FB_MODAL_CONFIG: {
            windowClass: 'signout-fb-modal-css',
            backdrop: 'static',
            keyboard: false,
            scrollable: false,
            centered: true
        },
        ORDER_TRACKING_MODAL_CONFIG: {
            windowClass: 'order-tracking-modal-css',
            backdrop: 'static',
            keyboard: false,
            scrollable: false,
            centered: true
        },
        CREATE_TIMINGS_MODAL: {
            windowClass: 'create-timings-modal-css',
            // backdrop: 'static',
            keyboard: false,
            scrollable: false,
            centered: true
        },
        FROM_RIGHT_MODAL: {
            windowClass: 'modal fade come-from-modal ',
            backdrop: 'static',
            keyboard: false,
            scrollable: false,
            centered: true
        },
        FROM_TOP_MODAL: {
            windowClass: 'come-from-top-modal',
            // backdrop: 'static',
            keyboard: false,
            scrollable: false,
            // centered: true
        },
        FROM_TOP_MODAL2: {
            windowClass: 'come-from-top-modal2',
            // backdrop: 'static',
            keyboard: false,
            scrollable: false,
            // centered: true
        },
        PROFILE_TOP_MODAL: {
            windowClass: 'profile-from-top-modal',
            keyboard: false,
            scrollable: false
        },
        DELIVERY_EXCUTIVE_MODAL: {
            windowClass: 'delivery-excutive-modal',
            keyboard: false,
            scrollable: false,
            centered: true
        },
        ORDER_LIST_FILTER_MODAL: {
            windowClass: 'order-list-filter-modal',
            keyboard: false,
            scrollable: false,
            centered: true
        },
        PRINT_INVOICE_MODAL: {
            windowClass: 'print-invoice-modal',
            keyboard: false,
            scrollable: false,
            centered: true
        },
        SIDEBAR_TOOLTIP_MODAL: {
            windowClass: 'sidebar-tooltip-modal',
            keyboard: false,
            scrollable: false
        },
        PAYMENT_MODAL: {
            windowClass: 'payment-modal',
            backdrop: 'static',
            keyboard: false,
            scrollable: false,
            centered: true
        },
        ORDER_FILTER_MODAL: {
            windowClass: 'order-filter-modal',
            keyboard: false,
            backdrop: 'static',
            scrollable: false
        },
        FILE_UPLOAD_MODAL: {
            windowClass: 'file-upload-modal',
            keyboard: false,
            backdrop: 'static',
            centered: true,
            scrollable: false
        },
        PROD_COLLECTION_MODAL: {
            windowClass: 'discount-prod-collection-modal',
            keyboard: false,
            backdrop: 'static',
            centered: true,
            scrollable: false
        },
        VIDEO_PLAYER_MODAL: {
            windowClass: 'video-player-modal',
            keyboard: false,
            backdrop: 'static',
            centered: true,
            scrollable: false
        },
        ORDER_REJECT_MODAL: {
            windowClass: ' reject-order-modal',
            // backdrop: 'static',
            keyboard: false,
            scrollable: false,
            centered: true
        },
        SMALL_MODAL: {
            windowClass: 'small-modal',
            backdrop: true,
            scrollable: false,
            centered: true
        },
    };

    return CONFIG[type];
}


export function getMessages(type) {
    const MESSAGES = {
        USER_NOT_FOUND_MSG: {
            title: 'Create account?',
            msg: 'No user found for this number, please sign up',
            btnClose: 'Cancel',
            btnConfirm: 'Create account',
            isLogout: true
        },

        LOGOUT_MSG: {
            title: 'Log Out?',
            msg: 'Are you sure you want to log out?',
            btnClose: 'I will stay',
            btnConfirm: 'Log Out',
            isLogout: true
        },
        FSSAI_MSG: {
            msg: 'Please complete FSSAI details within 2 months else outlet will be removed',
            btnClose: "Cancel",
            btnConfirm: "okay",
            isFSSAI: true
        },
        SIGNOUT_FACEBOOK: {
            img: 'assets/images/icons/fb-logout.svg',
            title: 'Are you leaving?',
            msg: 'Are you sure you want to signout? All your unsaved data will be lost.',
            btnClose: 'Cancel',
            btnConfirm: 'Signout',
            isLogoutFB: true
        },
        DISABLE_SCRATCHCARD: {
            img: 'https://sgp1.digitaloceanspaces.com/listing/restaurant-dashboard-v2/assets/images/Refer_Earn.svg',
            title: 'Do you want to disable this feature?',
            msg: 'If you disable, users will not get a scratch card for referral. You can change this anytime.',
            btnClose: 'Cancel',
            btnConfirm: 'Disable',
            isLogoutFB: false,
            scratchCardDisable: true
        },
        DELETE_POST: {
            img: 'assets/images/icons/post-delete.svg',
            title: 'Want to remove this post?',
            msg: 'By clicking delete,data related to this post will be removed completely.',
            btnClose: 'Cancel',
            btnConfirm: 'Delete',
            isDeletePost: true
        },
        DELETE_VARIANT: {
            title: 'Delete',
            img: 'assets/images/icons/post-delete.svg',
            msg: 'Are you sure you want to Delete Variant?',
            btnClose: 'Cancel',
            btnConfirm: 'Delete',
            deleteVariant: true,
        },
        DELETE_ADDON: {
            title: 'Delete',
            img: 'assets/images/icons/post-delete.svg',
            msg: 'Are you sure you want to Delete Addon?',
            btnClose: 'Cancel',
            btnConfirm: 'Delete',
            deleteAddon: true,
        },
        COUPON_DELETE_MSG: {
            img: 'assets/images/icons/post-delete.svg',
            title: 'Delete?',
            msg: 'Are you sure you want to delete coupon?',
            btnClose: 'Cancel',
            btnConfirm: 'Delete',
            isCouponDelete: true
        },
        BANNER_DELETE_MSG: {
            img: 'assets/images/icons/post-delete.svg',
            title: 'Want to remove this post?',
            msg: 'By clicking delete, data related to this post will be removed completely.',
            btnClose: 'Cancel',
            btnConfirm: 'Delete',
            isBannerDelete: true
        },
        MENU_ITEM_DELETE_MSG: {
            img: 'assets/images/icons/post-delete.svg',
            title: 'Delete?',
            msg: 'Are you sure you want to delete the item?',
            btnClose: 'Cancel',
            btnConfirm: 'Delete',
            isItemDelete: true
        },
        CLEAR_PLATE_MSG: {
            img: 'assets/images/icons/post-delete.svg',
            title: 'Clear cart',
            msg: 'Your cart already has some items from another outlet. Do you want to discard and add new items ?',
            previousOutlet: '',
            newOutlet: '',
            btnClose: 'Cancel',
            btnConfirm: 'Clear all',
            isPLateClear: true
        },
        CLEAR_ALL_DISH_MSG: {
            img: 'assets/images/icons/post-delete.svg',
            title: 'Clear cart',
            msg: 'Do you wish to discard the selected items from your Cart?',
            btnClose: 'Cancel',
            btnConfirm: 'Clear Cart',
            isClearAllDish: true
        },
        OUTLET_MENU_CLOSE_MSG: {
            title: '',
            msg: '',
            btn: 'Cancel',
            isOutletClose: true
        },
        DELETE_CATEGORY: {
            img: 'assets/images/icons/post-delete.svg',
            btnClose: "Cancel",
            btnConfirm: "okay",
            title: 'Delete Category',
            msg: 'Before deleting a category, remove or move items from it.',
        },
        DELETE_SUB_CATEGORY: {
            img: 'assets/images/icons/post-delete.svg',
            btnClose: "Cancel",
            btnConfirm: "okay",
            title: 'Delete Sub Category',
            msg: 'Before deleting a sub category, remove or move items from it.',
        },
        CATEGORY_STATUS_CHANGE: {
            // img: 'assets/images/icons/post-delete.svg',
            label: '',
            btnClose: "Cancel",
            btnConfirm: "Confirm",
            title: ' Do you want to make this item available? ',
            msg: 'click cancel to return to the page or confirm to the page.',
        }
    };
    return MESSAGES[type];
}

export const OTP_CONFIG = {
    allowNumbersOnly: true,
    length: 4,
    disableAutoFocus: false,
    inputStyles: {
        'width': '48px',
        'height': '48px',
        'background': 'var(--white-clr) 0% 0% no-repeat padding-box',
        'border': '1px solid var(--primary-dark-clr)',
        'border-radius': '4px',
        'font-size': '1.2rem',
        'text-align': 'center',
        'color': 'var(--black-color)',
        'outline': '0',
        'margin': '10px'
    },

}


export const DAYS = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];

export const MINUTES = ['00', '15', '30', '45'];

export const MENU_LAYOUT_CODE = {
    NORMAL_MENU_ITEM: 11,
    NORMAL_MENU_ITEM_WITH_LEFT_IMG: 12,
    NORMAL_MENU_ITEM_WITH_RIGHT_IMG: 16,
    TOP_ITEM: 15,

    // BEST_SELLER_ITEM: 14,
    // RECOMMENDED_ITEM: 13,
    // NORMAL_MENU_ITEM: 12,
    // TOP_ITEM: 15,
    // NORMAL_MENU_ITEM_WITHOUT_IMG: 11,
    // NORMAL_MENU_ITEM_WITH_RIGHT_IMG: 16,
}

export const PROGRESS_BAR_DATA = {
    merchant: [
        { id: 1, name: 'General Info', route: '/panel/merchant/info' },
        { id: 2, name: 'Subscription', route: '/panel/merchant/subscription' }
    ],
    posOrder: [
        { id: 1, name: 'Customer Details', route: '/panel/new-order/user-info' },
        { id: 2, name: 'Item Selection', route: '/panel/new-order/item-selection' },
        { id: 3, name: 'Checkout', route: '/panel/new-order/check-out' }
    ]
}
export const PLAN_FREQUENCY_UNIT = {
    week: 'weekly',
    month: 'monthly',
    year: 'yearly'
}
export const ACCOUNT_UNIT = {
    food: {
        ACCOUNT_TITLE: 'Rest'
    },
    grocery: {
        ACCOUNT_TITLE: 'store'

    },
    stationary: {
        ACCOUNT_TITLE: 'store'

    }
}

export const BUSINESS_TYPE_LIST = [
    { key: 'food', val: 'Restaurant' },
    { key: 'grocery', val: 'Grocery' },
    { key: 'stationary', val: 'Stationary' },
    { key: 'fruits&veggies', val: 'Fruits & Vegetables' },
    { key: 'meat&poultry', val: 'Meat & Poultry' },
    { key: 'bakery', val: 'Bakery' },
    { key: 'others', val: 'Others' },
]

export const SLOT_TYPE_TIME = [
    { key: "0000", value: "12:00 AM" }, { key: "0030", value: "12:30 AM" }, { key: "0100", value: "01:00 AM" },
    { key: "0130", value: "01:30 AM" }, { key: "0200", value: "02:00 AM" }, { key: "0230", value: "02:30 AM" },
    { key: "0300", value: "03:00 AM" }, { key: "0330", value: "03:30 AM" }, { key: "0400", value: "04:00 AM" },
    { key: "0430", value: "04:30 AM" }, { key: "0500", value: "05:00 AM" }, { key: "0530", value: "05:30 AM" },
    { key: "0600", value: "06:00 AM" }, { key: "0630", value: "06:30 AM" }, { key: "0700", value: "07:00 AM" },
    { key: "0730", value: "07:30 AM" }, { key: "0800", value: "08:00 AM" }, { key: "0830", value: "08:30 AM" },
    { key: "0900", value: "09:00 AM" }, { key: "0930", value: "09:30 AM" }, { key: "1000", value: "10:00 AM" },
    { key: "1030", value: "10:30 AM" }, { key: "1100", value: "11:00 AM" }, { key: "1130", value: "11:30 AM" },
    { key: "1200", value: "12:00 PM" }, { key: "1230", value: "12:30 PM" }, { key: "1300", value: "1:00 PM" },
    { key: "1330", value: "1:30 PM" }, { key: "1400", value: "2:00 PM" }, { key: "1430", value: "2:30 PM" },
    { key: "1500", value: "3:00 PM" }, { key: "1530", value: "3:30 PM" }, { key: "1600", value: "4:00 PM" },
    { key: "1630", value: "4:30 PM" }, { key: "1700", value: "5:00 PM" }, { key: "1730", value: "5:30 PM" },
    { key: "1800", value: "6:00 PM" }, { key: "1830", value: "6:30 PM" }, { key: "1900", value: "7:00 PM" },
    { key: "1930", value: "7:30 PM" }, { key: "2000", value: "8:00 PM" }, { key: "2030", value: "8:30 PM" },
    { key: "2100", value: "9:00 PM" }, { key: "2130", value: "9:30 PM" }, { key: "2200", value: "10:00 PM" },
    { key: "2230", value: "10:30 PM" }, { key: "2300", value: "11:00 PM" }, { key: "2330", value: "11:30 PM" },
    { key: "2359", value: "11:59 PM" },
];
export const WEEKDAYS = [
    { key: '0', val: 'Sun', select: false },
    { key: '1', val: 'Mon', select: false },
    { key: '2', val: 'Tue', select: false },
    { key: '3', val: 'Wed', select: false },
    { key: '4', val: 'Thu', select: false },
    { key: '5', val: 'Fri', select: false },
    { key: '6', val: 'Sat', select: false },
];

export const WEEKS = [false, false, false, false, false, false, false];
export const DISCOUNT_SUMMARY = {
    'main': 'Available on online sales channels',
    // 'totalTimes': 'Limit of (Enter val) uses',
    // 'minAmount': 'Minimum purchase of  (Enter val)',
    // 'minQty': 'Minimum purc   'discountValue': ''


}


export const APPLIED_TO = {
    all: 'all products',
    collection: 'collections',
    items: 'products'
}

export const FOOD_TAGS_LIST = [
    {
        id: "6002984430523",
        name: "Biryani"
    },
    {
        id: "6003668857118",
        name: "Pizza"
    },
    {
        id: "6003125948045",
        name: "Desserts"
    },
    {
        id: "6003641846820",
        name: "Veganism"
    },
    {
        id: "6003278094599",
        name: "Ice cream"
    },
]

export const AGM_MAP_CUSTOM_STYLES = [
    {
        featureType: "all",
        elementType: "labels.text",
        stylers: [{ visibility: "on" }]
    }, {
        featureType: "road",
        elementType: "labels",
        stylers: [{ visibility: "on" }]
    }, {
        featureType: "poi",
        stylers: [{ visibility: "off" }]
    }, {
        featureType: "transit",
        stylers: [{ "visibility": "off" }]
    }
]

export const markerOptions = [{
    origin: {
        icon: {
            url: '../../../../assets/images/icons/agm-map-icons/delivery-bike.svg', scaledSize: { height: 30, width: 30 },
        }
    },
    destination: {
        icon: {
            url: '../../../../assets/images/icons/agm-map-icons/restaurant.svg', scaledSize: { height: 30, width: 30 },
        }
    },
},
{
    origin: {
        icon: {
            url: '../../../../assets/images/icons/agm-map-icons/delivery-bike.svg', scaledSize: { height: 30, width: 30 },
        }
    },
    destination: {
        icon: {
            url: '../../../../assets/images/icons/agm-map-icons/home.svg', scaledSize: { height: 30, width: 30 },
        }
    },
}]
export const riderIcon = [{
    icon: {
        url: '../../../../assets/images/icons/agm-map-icons/home.svg', scaledSize: { height: 30, width: 30 },
    }
},
{
    icon: {
        url: '../../../../assets/images/icons/agm-map-icons/restaurant.svg', scaledSize: { height: 30, width: 30 },
    }
}]

export const mapIcon = [{
    icon: {
        url: '../../../../assets/images/icons/agm-map-icons/home.svg', scaledSize: { height: 30, width: 30 },
    }
},
{
    icon: {
        url: '../../../../assets/images/icons/agm-map-icons/restaurant.svg', scaledSize: { height: 30, width: 30 },
    }
},
{
    icon: {
        url: '../../../../assets/images/icons/agm-map-icons/delivery-bike.svg', scaledSize: { height: 30, width: 30 },
    }
}]

export const GSTIN_TAXES = [
    { key: "0", value: "0%" },
    { key: "5", value: "5%" },
    { key: "12", value: "12%" },
    { key: "18", value: "18%" },
];

export const PLACEHOLDER_IMGS = {
    banners: [
        'assets/images/placeholder-images/banner-pink.png',
        'assets/images/placeholder-images/banner-grey.png'
    ],
}

export const THEME_COLOR = {
    'categorySelected': '#213454',
    'categoryNonSelected': '#71767a'
}
export const industryType = [
    { key: "restaurant", value: "food" },
    { key: "grocery", value: "grocery" },
    { key: "stationary", value: "others" },
    { key: "meat and poultry", value: "meat and poultry" },
];

export const DELIVERY_CHARGING_TYPES = [
    {
        key: "cartValueLimit",
        title: "On cart value",
        description: "Charges to be based on the cart value",
    },
    {
        key: "freeDelivery",
        title: "Free Delivery for Customer",
        description: "Delivery charges to be paid by you, no charges to be paid by the customer.",
    },
    {
        key: "customerSpecific",
        title: "Paid by Customer",
        description: "Delivery charges to be paid by the customers.",
    },
    {
        key: "partnerSpecific",
        title: "Paid by Partner",
        description: "Delivery charges to be paid by you, no charges to be paid by the customer.",
    },
    {
        key: "distributeCharges",
        title: "Distribute charges",
        description: "Delivery charges to be split between you and the customer.",
    },
    {
        key: "dynamic",
        title: "Dynamic",
        description: "Charges to fluctuate as per marketplace",
    },
]

export const DEL_CAL_CHARGING_TYPES = [
    {
        key: "cartValueLimit",
        title: "On Cart Value",
        description: "Charges to be calculated based on cart value",
    },
    {
        key: "byDistance",
        title: "Delivery Distance",
        description: "Charges to be calculated on the delivery distance",
    },
    // {
    //     key: "share",
    //     title: "Sharing",
    //     description: "sharing between customer and you",
    // },
]
export const TEMPLATE_TYPES = [
    {
        val: "yumzyx",
        title: "YumzyX Templates",
        description: "Choose from templates made by our design team.",
    },
    {
        val: "new",
        title: "New Templates",
        description: "You can make your own post template.",
    },
]
export const CALL_TO_ACTION = [
    'See menu', 'Shop now', 'Signup', 'Apply now'
]

export const COLOR_PALETTE = [
    {
        "primaryColor": "#40A944",
        "primaryColorGrd1": "#40A944FA",
        "primaryColorGrd2": "#40A9441A",
        "secondaryColor": "#ffffff",

    },
    {
        "primaryColor": "#264653",
        "primaryColorGrd1": "#264653FA",
        "primaryColorGrd2": "#2646531A",
        "secondaryColor": "#2A9D8F",

    },
    {
        "primaryColor": "#845EC2",
        "primaryColorGrd1": "#845EC2FA",
        "primaryColorGrd2": "#845EC21A",
        "secondaryColor": "#F9F871",

    },
    {
        "primaryColor": "#FF7C8D",
        "primaryColorGrd1": "#FF7C8DFA",
        "primaryColorGrd2": "#FF7C8D1A",
        "secondaryColor": "#2F4858",

    },
    {
        "primaryColor": "#0F38DE",
        "primaryColorGrd1": "#0F38DEFA",
        "primaryColorGrd2": "#0F38DE1A",
        "secondaryColor": "#FADE4B",

    },
    {
        "primaryColor": "#ECAC4E",
        "primaryColorGrd1": "#ECAC4EFA",
        "primaryColorGrd2": "#ECAC4E41A",
        "secondaryColor": "#384854",

    },
    {
        "primaryColor": "#006D77",
        "primaryColorGrd1": "#006D77FA",
        "primaryColorGrd2": "#006D771A",
        "secondaryColor": "#E29578",

    },

    {
        "primaryColor": "#541847",
        "primaryColorGrd1": "#541847FA",
        "primaryColorGrd2": "#5418471A",
        "secondaryColor": "#CF2D30",

    },
    {
        "primaryColor": "#402D2E",
        "primaryColorGrd1": "#402D2EFA",
        "primaryColorGrd2": "#402D2E1A",
        "secondaryColor": "#F8DCD2",

    },
    {
        "primaryColor": "#5720E5",
        "primaryColorGrd1": "#5720E5FA",
        "primaryColorGrd2": "#5720E51A",
        "secondaryColor": "#66D7C5",

    },
    {
        "primaryColor": "#000000",
        "primaryColorGrd1": "#000000FA",
        "primaryColorGrd2": "#0000001A",
        "secondaryColor": "#ffffff",

    },
    {
        "primaryColor": "#43675A",
        "primaryColorGrd1": "#43675AFA",
        "primaryColorGrd2": "#43675A1A",
        "secondaryColor": "#E96137",

    },
    {
        "primaryColor": "#FFCDB2",
        "primaryColorGrd1": "#FFCDB2FA",
        "primaryColorGrd2": "#FFCDB21A",
        "secondaryColor": "#2F4858",

    },
    {
        "primaryColor": "#BEE9E8",
        "primaryColorGrd1": "#BEE9E8FA",
        "primaryColorGrd2": "#BEE9E81A",
        "secondaryColor": "#5FA8D3",

    },
    {
        "primaryColor": "#CB997E",
        "primaryColorGrd1": "#CB997EFA",
        "primaryColorGrd2": "#CB997E1A",
        "secondaryColor": "#6B705C",

    },
    {
        "primaryColor": "#03045E",
        "primaryColorGrd1": "#03045EFA",
        "primaryColorGrd2": "#03045E1A",
        "secondaryColor": "#CAF0F8",

    },
    {
        "primaryColor": "#081C15",
        "primaryColorGrd1": "#081C15FA",
        "primaryColorGrd2": "#081C151A",
        "secondaryColor": "#D8F3DC",

    },
    {
        "primaryColor": "#F72585",
        "primaryColorGrd1": "#F72585FA",
        "primaryColorGrd2": "#F725851A",
        "secondaryColor": "#4CC9F0",

    },
    {
        "primaryColor": "#212529",
        "primaryColorGrd1": "#212529FA",
        "primaryColorGrd2": "#2125291A",
        "secondaryColor": "#F8F9FA",

    },
    {
        "primaryColor": "#4F000B",
        "primaryColorGrd1": "#4F000BFA",
        "primaryColorGrd2": "#4F000B1A",
        "secondaryColor": "#FFE6A7",

    }
]
export const DELIVERY_PROVIDERS = [
    {
        name: 'Dunzo',
        maxDistance: 12,
        icon: '../../../../assets/images/delivery_providers/dunzo_icon.png'
    },
    {
        name: 'Shadowfax',
        maxDistance: 7,
        icon: '../../../../assets/images/delivery_providers/shadowfax_icon.png'
    },
    {
        name: 'Rapido',
        maxDistance: 10,
        icon: '../../../../assets/images/delivery_providers/rapido_icon.png'
    },
    {
        name: 'Wefast',
        maxDistance: 9,
        icon: '../../../../assets/images/delivery_providers/wefast_icon.png'
    },
]

export const COUPON_STATUS_LIST = [
    { key: 'ALL', name: 'All' },
    { key: 'ACTIVE', name: 'Active' },
    { key: 'EXPIRED', name: 'Expired' }
]
export enum DashboardStepStatus {
    incomplete = 'incomplete',
    complete = 'complete'
}

export const DISCOUNT_STATUS_CLR = {
    expired: '#ffb300',
    active: '#3cc480',
    inactive: '#e8423e'
}

export const BANNER_STATUS_CLR = {
    active: '#3CC480',
    expired: '#ffb300',
    inactive: '#e8423e'
}


export const CAMPAIGN_STATUS_CLR = {
    expired: '#ffb300',
    ACTIVE: '#3cc480',
    INACTIVE: '#e8423e',
    drafts: '#B0BAC9',
    inreview: '#FFB300'
}

export const CAMPAIGN_STATUS = {
    ACTIVE: 'ACTIVE',
    INACTIVE: 'INACTIVE',
}


export const DISCOUNT_APPLY_TYPE = {
    'items': 'products',
    'collection': 'collections',
    'category': 'collections'
}

export const PAGE_TITLE = {
    dashboard: 'Dashboard',
    order: 'Manage Orders',
    product: 'Products',
    category: 'Categories',
    import: ' Item Import',
    outletSetup: 'Outlet Setup',
    social: 'Socials',
    discount: 'Discount',
    omniChannel: 'Omni-Channel',
    report: 'Reports',

}

export const PROFILE_POP_OVER_MENU = [
    { title: 'Account', type: 'ACCOUNT' },
    // { title: 'Account Setting', type: 'ACCOUNT_SETTING' },
    // { title: 'Billing', type: 'BILLING' },
    { title: 'Logout', type: 'LOGOUT' }
]

export const ERROR_CART_MESSAGES = {
    1010: {
        imgUrl: 'assets/images/icons/cart/item-unavailable.svg',
        title: 'not serving',
        desc: '',
        subTitle: '',
        btnTxt: 'Remove Item',
        btnClassCSS: 'btn-theme-bg',
        titleCss: 'red-title-txt',
        btnAction: 'ITEM_NOT_SERVING'
    },
    1011: {
        imgUrl: 'assets/images/icons/cart/restaurant-closed.svg',
        title: 'Outlet is closed',
        desc: '',
        subTitle: '',
        btnTxt: 'Check other restaurant',
        btnClassCSS: 'btn-theme-bg',
        titleCss: 'red-title-txt',
        btnAction: 'OUTLET_NOT_SERVING'
    },
    1012: {
        imgUrl: ' ',
        title: '',
        desc: '',
        subTitle: '',
        btnTxt: '',
        btnClassCSS: 'btn-theme-bg',
        titleCss: 'red-title-txt',
        btnAction: 'OFFER_INVALID'
    },
    1013: {
        imgUrl: ' ',
        title: '',
        desc: '',
        subTitle: '',
        btnTxt: '',
        btnClassCSS: 'btn-theme-bg',
        titleCss: 'red-title-txt',
        btnAction: 'OFFER_MIN_ORDER'
    },
    1014: {
        imgUrl: 'assets/images/icons/cart/new-location.svg',
        title: 'Delivery person unavailable',
        desc: 'We are not serving in this location.',
        subTitle: '',
        btnTxt: 'Try Other Location',
        btnClassCSS: 'btn-theme-bg',
        titleCss: 'red-title-txt',
        btnAction: 'DELIVERY_PERSON_UNAVAILABLE'
    },
    1015: {
        imgUrl: 'assets/images/icons/cart/new-location.svg',
        title: 'Location unserviceable',
        desc: 'We are not serving this location.',
        subTitle: '',
        btnTxt: 'Try Other Location',
        btnClassCSS: 'btn-theme-bg',
        titleCss: 'red-title-txt',
        btnAction: 'LOCATION_UNSERVICEABLE'
    },
    'DELIVERY_ADDRESS': {
        imgUrl: 'assets/images/icons/cart/deliver-to-home.svg',
        title: '',
        desc: '',
        subTitle: 'Change address',
        btnTxt: '',
        btnClassCSS: 'btn-theme-bg',
        titleCss: 'black-title-txt',
        btnAction: 'PLACE_ORDER'
    },
    'ADD_ADDRESS': {
        imgUrl: 'assets/images/icons/cart/new-location.svg',
        title: 'You are in new location !',
        desc: '',
        subTitle: '',
        btnTxt: 'Add Address To Proceed',
        btnClassCSS: 'btn-theme-bg',
        titleCss: 'black-title-txt',
        btnAction: 'ADD_ADDRESS'
    },
    'SELECT_ADDRESS': {
        imgUrl: 'assets/images/icons/cart/new-location.svg',
        title: 'You are in new location !',
        desc: '',
        subTitle: '',
        btnTxt: 'Try Other Location',
        btnClassCSS: 'btn-theme-bg',
        titleCss: 'black-title-txt',
        btnAction: 'SELECT_ADDRESS'
    },
    'LOGIN': {
        imgUrl: '',
        title: '',
        desc: '',
        subTitle: '',
        btnTxt: 'Login',
        btnClassCSS: 'btn-theme-bg',
        titleCss: 'black-title-txt',
        btnAction: 'LOGIN'
    },
    'SELF_PICKUP': {
        imgUrl: '',
        title: '',
        desc: '',
        subTitle: '',
        btnTxt: 'Pay',
        btnClassCSS: 'btn-theme-bg',
        titleCss: 'black-title-txt',
        btnAction: 'PLACE_ORDER'
    }
}
export const ADDRESS_TAG_IMG = {
    home: 'assets/images/icons/cart/deliver-to-home.svg',
    office: 'assets/images/icons/cart/deliver-to-office.svg',
    others: 'assets/images/icons/cart/deliver-to-other.svg'
}

export const CHARGES = {
    packingCharges: 'Packing Charges',
    restaurantCharges: 'Outlet Charges',
    surgeCharges: 'Surge Charges',
    deliveryCharge: 'Delivery Charges'
}

export const COUNTRY_TYPE = {
    inr: { name: 'INR', symbol: '₹', code: '+91' },
    usd: { name: 'USD', symbol: '$', code: '+1' },
    cad: { name: 'CAD', symbol: '$', code: '+1' }
}

export const VEG_NONVEG_EGG = [
    {
        idFor: "veg",
        value: "Veg",
    },
    {
        idFor: "nonveg",
        value: "Non-Veg",
    },
    {
        idFor: "egg",
        value: "Egg",
    }
]
export const GETTING_STARTED_STEPS = [
    {
        stepTitle: 'Create your first outlet',
        stepSequence: 'Step 1',
        key: 'outletCreation',
        stepDescription: 'Configure an outlet to receive and prepare orders.',
        anchor: "Know more",
        createButton: "Create Outlet",
        status: "incomplete",
        stepImg: "https://sgp1.digitaloceanspaces.com/listing/restaurant-dashboard-v2/assets/images/v3Steps/outlet-setup-video-img.png",
        stepBgColor: '#DE9290',
        videoUrl: "https://www.youtube-nocookie.com/embed/K1F8zI0liuM",
    },
    {
        stepSequence: 'Step 2',
        key: 'deploy',
        stepTitle: 'Create your domain name',
        stepDescription: 'Configure a domain name for your outlet to receive online orders.',
        anchor: "Know more",
        createButton: "Create Domain",
        status: "incomplete",
        stepImg: "https://sgp1.digitaloceanspaces.com/listing/restaurant-dashboard-v2/assets/images/v3Steps/create-outlet-video-image.png",
        stepBgColor: '#7AEDFA',
        videoUrl: "https://www.youtube-nocookie.com/embed/wOaRV5bQ-Kk",
    },
    {
        stepSequence: 'Step 3',
        key: 'categoryCreation',
        stepTitle: 'Create categories for your items',
        stepDescription: 'Configure categories to segregate your items under different types',
        anchor: "Know more",
        createButton: "Create Categories",
        status: "incomplete",
        stepImg: "https://sgp1.digitaloceanspaces.com/listing/restaurant-dashboard-v2/assets/images/v3Steps/create-categories-video-image.png",
        stepBgColor: '#9F84D0',
        videoUrl: "https://www.youtube-nocookie.com/embed/Ybfjy2PD0es",
    },
    {
        stepSequence: 'step 4',
        key: 'menuCreation',
        stepTitle: 'Create items for customer view',
        stepDescription: 'Configure individual items and make them available for ordering.',
        anchor: "Know more",
        createButton: "Create Items",
        status: "incomplete",
        stepImg: "https://sgp1.digitaloceanspaces.com/listing/restaurant-dashboard-v2/assets/images/v3Steps/layout-selection-video-image.png",
        stepBgColor: '#82EFA4',
        videoUrl: "https://www.youtube-nocookie.com/embed/zDjbuOhSr1s",
    }
]
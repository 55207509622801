import { Component, HostListener } from '@angular/core';
import { NavigationEnd, NavigationStart, Router } from '@angular/router';
import { element } from 'protractor';
import { ROUTES_STR } from './core/constants/app-constants';
import { StorageService } from './core/services/storage.service';
import { AppData } from './shared/services/app-data.service';
import { AuthService } from './shared/services/auth.service';
import { SocketService } from './shared/services/socket.service';
import { Location } from '@angular/common'

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'restaurant-dashboard-v2';
  
  constructor(
    private storageService: StorageService,
    private appData: AppData,
    private authService: AuthService,
    private socketService: SocketService,
  ) {

    
  }

  

}

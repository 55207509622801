import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-no-internet',
  templateUrl: './no-internet.component.html',
  styleUrls: ['./no-internet.component.scss']
})
export class NoInternetComponent implements OnInit {

  constructor(
    private activatedRoute: ActivatedRoute,
    private route: Router
  ) { }

  ngOnInit() {
    if (window.navigator.onLine){
      this.activatedRoute.queryParams.subscribe(
        (value: any) =>{
          console.log(value);
          this.route.navigate([value.navigateFrom]);
        }
      )
    }
  }

}

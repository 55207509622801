import { AppData } from "./app-data.service";

export type URL_KEYS =
  // Accounts Related URLs
  'login' | 'logout' | 'initSignup' | 'sendOtp' | 'forgetPassword'
  | 'resetPassword' | 'completeRestProfile' | 'subinitSignup' | 'subcompleteSignup' | 'restData'
  | 'storeNameValidation'

  | 'signup' | 'completeSignup' | 'restConfig'

  // Subscription
  | 'getPlan' | 'mySubscriptions' | 'planSubscribe'

  // ScratchCard Urls
  | 'scratchCardToggle' | 'scratchCardSetConfig' | 'scratchCardGetConfig'
  // OutLet Levels URLs
  | 'fetchOutletDetails' | 'outlet' | 'allowOutletAdd' | 'outletList' | 'viewUsers' | 'setAvailability'
  | 'fssaiCertificateLink' | 'outletDetails' | 'verifyBankAccount'

  // Account Verification Api(s)
  | 'initVerification' | 'verify'


  //Discount
  | 'discountV3' | 'dCollection' | 'dProducts' | 'dGetDiscounts'
  | 'dGetDisById' | 'dGetCatByCatId' | 'dGetCatItemByCatIds' | 'dGetItmByItmId'
  | 'dUpdateCoupon' | 'dDeleteCoupon'

  // Others URLs
  | 'fssaiCheck' | 'gstInCheck' | 'getBankDetails'

  // menu import
  | 'addMappingItem'
  | 'menuImport' | 'menuItemCreate' | 'menuItemsList' | 'menuItemImage' | 'menuItemById' | 'uploadStatus'
  | 'processImageMapping' | 'menuItemUpdate' | 'nextAvailabilityItem' | 'menuItemDelete' | 'zipUploadUrl'
  | 'addMenuSlot' | 'getMenuSlotDetails'

  // category Urls
  | 'getCategory' | 'addSubCategory' | 'addCategory' | 'updateCategory'
  | 'deleteCategory' | 'updateSubcategory' | 'deleteSubcategory' | 'categorySwitch' | 'subCategorySwitch'|

  // Addons
  'createAddons' | 'getAddons' | 'deleteAddon' |

  // offers
  'createOffer' | 'getOffers' | 'deleteOffer' |

  //banners
  'createBanner' | 'getBanners' | 'changeBannerStatus' | 'getBannerById' | 'updateBanner' |

  // Order Management
  'changeStatus' | 'getOrders' | 'offMenuItem' | 'deliveryInfo'

  // subscription
  | 'getPlansList' | 'createPlanPayment' | 'checkPaymentStatus' |

  // support
  'getSupportCategories' | 'createSupport' |

  'categoryReorder' |

  // Report
  'getReport' | 'getOrderDetails' | 'downloadReport' |

  // Setup
  'setupDetails' |

  //promotion
  'promotionList' | 'themeValidation' | 'themeEnroll' | 'themeLeave' | 'promoItemList' |


  'newOrderSubscription' | 'getCurrentAddress' |

  // Engagement

  'facebookUsers' | 'facebookLogin' | 'facebookPages' | 'facebookPost' | 'createPostWithImg' | 'getFacebookPosts' | 'deletefacebookPost' |
  'facebookSingOut' | 'checkFacebookLogin' | 'callToAction' | 'adPost' | 'getAdPost' |

  // Campaign
  'createCampaign' | 'getCampaignById' | 'getCampaignTypeList' | 'getCampaign' | 'foodTags' | 'createUpdateAdSet' | 'getAdSetById' |

  //Manage Campaign
  'getCampaigns' | 'deleteCampaign' | 'updateCampaignStatus' | 'createPixel' | 'getPixelList' |

  // cuisines and cityLocality
  'getCuisinesAndCities' |

  // gallery
  'uploadGalleryImg' | 'getGalleryImages' | 'getAllGalleryImages' | 'deleteGalleryImages' | 

  // POS orders
  'getCart' | 'addItemToCart' | 'applyCoupon' | 'applySelfPickup' | 'updateAddonVariant' | 'changeItemCount' |
  'preOrderCheckout' | 'placeOrder' | 'postCheckout' | 'getAllCoupon' | 'applyCoupon' | 'removeCoupon' |
  'clearPlate' | 'noSelfPickup' | 'selfPickup' | 'cartId' | 'getCustomerDetails' | 'updateCustomerDetails' | 'createPayment' |
  'sendPaymentLink' | 'outletNearByCustomer' | 'billInfo' | 'directDelivery' |

  'getAllItemsByCategory' | 'wallet' |


  // tracking
  'Map' | 'DeliveryPartnerList' | 'DeliveryStatus' | 'createCart' |

  // CRM
  'crmList' | 'crmImportFile' |'getAllOutlets' | 'migrateOutlet' |

  //CSV Upload

  'csvUpload'

const UrlMapping = {
  //login and sign up

  login: { url: AppData.baseUrl + '/account/login', showMsg: false, showLoading: true },
  signup: { url: AppData.baseUrl + '/account/v2/signup', showMsg: true, showLoading: true },
  logout: { url: AppData.baseUrl + '/account/logout', showMsg: false, showLoading: false },
  initSignup: { url: AppData.baseUrl + '/account/initSignup', showMsg: false, showLoading: false },
  sendOtp: { url: AppData.baseUrl + '/account/sendOtp', showMsg: false, showLoading: false },
  completeSignup: { url: AppData.baseUrl + '/account/v2/complete-signup', showMsg: false, showLoading: false },
  forgetPassword: { url: AppData.baseUrl + '/account/forgetPassword', showMsg: false, showLoading: false },
  resetPassword: { url: AppData.baseUrl + '/account/resetPassword', showMsg: true, showLoading: false },
  completeRestProfile: { url: AppData.baseUrl + '/account/completeRestProfile', showMsg: true, showLoading: false },
  subinitSignup: { url: AppData.baseUrl + '/account/subuser/initSignup', showMsg: false, showLoading: false },
  subcompleteSignup: { url: AppData.baseUrl + '/account/subuser/completeSignup', showMsg: true, showLoading: false },
  restData: { url: AppData.baseUrl + '/account/restData', showMsg: false, showLoading: false },
  storeNameValidation: { url: AppData.baseUrl + '/account/v2/signupValidation', showMsg: false, showLoading: false },
  restConfig: { url: AppData.baseUrl + '/account/restConfig', showMsg: true, showLoading: false },

  //Subscriptions plan
  getPlan: { url: AppData.baseUrl + '/plans', showMsg: false, showLoading: false },
  mySubscriptions: { url: AppData.baseUrl + '/plans/me', showMsg: false, showLoading: false },
  planSubscribe: { url: AppData.baseUrl + '/plans/subscribe', showMsg: false, showLoading: true },

  // OutLet Levels URLs
  outlet: { url: AppData.baseUrl + '/outlet', showMsg: true, showLoading: false },
  verifyBankAccount: { url: AppData.baseUrl + '/outlet', showMsg: false, showLoading: false },
  allowOutletAdd: { url: AppData.baseUrl + '/outlet/allowOutletAdd', showMsg: false, showLoading: false },
  fetchOutletDetails: { url: AppData.baseUrl + '/outlet', showMsg: false, showLoading: false },
  outletList: { url: AppData.baseUrl + '/outlet/outletList', showMsg: false, showLoading: false },
  viewUsers: { url: AppData.baseUrl + '/outlet/viewUsers', showMsg: false, showLoading: false },
  setAvailability: { url: AppData.baseUrl + '/outlet/setAvailability', showMsg: false, showLoading: false },
  fssaiCertificateLink: { url: AppData.baseUrl + '/outlet/fssaiLink', showMsg: false, showLoading: false },
  outletDetails: { url: AppData.baseUrl + '/outlet', showMsg: false, showLoading: false },

  // Account Verification Api(s)
  initVerification: { url: AppData.baseUrl + '/outlet/bank/account/initVerification', showMsg: false, showLoading: false },
  verify: { url: AppData.baseUrl + '/outlet/bank/account/verify', showMsg: false, showLoading: false },

  //Discount
  discountV3: { url: AppData.baseUrl + '/coupon/dashboard/', showMsg: true, showLoading: true },
  dCollection: { url: AppData.baseUrl + '/menu/category/getCatsByOutlet', showMsg: false, showLoading: false },
  dProducts: { url: AppData.baseUrl + '/menu', showMsg: false, showLoading: false },
  dGetDiscounts: { url: AppData.baseUrl + '/coupon/dashboard/', showMsg: false, showLoading: true },
  dGetDisById: { url: AppData.baseUrl + '/coupon/dashboard/', showMsg: false, showLoading: true },
  dGetCatItemByCatIds: { url: AppData.baseUrl + '/menu/category/items', showMsg: false, showLoading: false },
  dGetCatByCatId: { url: AppData.baseUrl + '/menu/category/list', showMsg: false, showLoading: false },
  dGetItmByItmId: { url: AppData.baseUrl + '/menu', showMsg: false, showLoading: false },
  dUpdateCoupon: { url: AppData.baseUrl + '/coupon/dashboard/', showMsg: true, showLoading: true },
  dDeleteCoupon: { url: AppData.baseUrl + '/coupon/dashboard/', showMsg: false, showLoading: true },

  // Others URLs
  fssaiCheck: { url: AppData.fssaiBaseUrl + '/fssai-validator/', showMsg: false, showLoading: false },
  gstInCheck: { url: AppData.gstInCheckUrl + '/service/gstnSearch', showMsg: false, showLoading: false },
  getBankDetails: { url: AppData.ifscNumberUrl + '/api/v1/ifsc', showMsg: false, showLoading: false },

  // Menu import
  menuImport: { url: AppData.baseUrl + '/menu/menuExcelImport', showMsg: true, showLoading: false },
  zipUploadUrl: { url: AppData.baseUrl + '/menu/zipUploadUrl', showMsg: false, showLoading: false },
  uploadStatus: { url: AppData.baseUrl + '/outlet/uploadStatus', showMsg: false, showLoading: false },
  processImageMapping: { url: AppData.baseUrl + '/menu/processImageMapping', showMsg: false, showLoading: false },


  // Menu item Create
  menuItemCreate: { url: AppData.baseUrl + '/menu/item/addItem', showMsg: true, showLoading: true },
  menuItemImage: { url: AppData.baseUrl + '/api/upload/uploadOne', showMsg: true, showLoading: false },
  menuItemDelete: { url: AppData.baseUrl + '/menu/item/deleteItem', showMsg: true, showLoading: false },
  menuItemUpdate: { url: AppData.baseUrl + '/menu/item/updateItem', showMsg: true, showLoading: true },

  // Menu management
  menuItemsList: { url: AppData.baseUrl + '/menu/item/getAllItemsByCategory', showMsg: false, showLoading: true },
  menuItemById: { url: AppData.baseUrl + '/menu/item/getItem', showMsg: false, showLoading: true },
  nextAvailabilityItem: { url: AppData.baseUrl + '/menu/item/setAvailability', showMsg: true, showLoading: false },
  addMenuSlot: { url: AppData.baseUrl + '/menu/item/opHours', showMsg: true, showLoading: false },
  getMenuSlotDetails: { url: AppData.baseUrl + '/menu/item/opHours', showMsg: false, showLoading: false },

  // category Url
  getCategory: { url: AppData.baseUrl + '/menu/category/getCatsByOutlet', showMsg: false, showLoading: false },
  addCategory: { url: AppData.baseUrl + '/menu/category/addCategory', showMsg: true, showLoading: false },
  addSubCategory: { url: AppData.baseUrl + '/menu/subCategory/addSubCategory', showMsg: true, showLoading: false },
  updateCategory: { url: AppData.baseUrl + '/menu/category/editCategory', showMsg: true, showLoading: false },
  updateSubcategory: { url: AppData.baseUrl + '/menu/subCategory/editSubCategory', showMsg: true, showLoading: false },
  deleteCategory: { url: AppData.baseUrl + '/menu/category/deleteCategory', showMsg: true, showLoading: false },
  deleteSubcategory: { url: AppData.baseUrl + '/menu/subCategory/deleteSubCategory', showMsg: true, showLoading: false },
  offMenuItem: { url: AppData.baseUrl + '/menu/item/setAvailability', showMsg: false, showLoading: false },
  subCategorySwitch: { url: AppData.baseUrl + '/menu/subCategory', showMsg: true, showLoading: false },
  categorySwitch: { url: AppData.baseUrl + '/menu/category', showMsg: true, showLoading: false },

  // Mapping Items
  addMappingItem: { url: AppData.baseUrl + '/menu/item/mapItems', showMsg: true, showLoading: false },
  // addons
  createAddons: { url: AppData.baseUrl + '/menu/addon/createAddon', showMsg: true, showLoading: false },
  getAddons: { url: AppData.baseUrl + '/menu/addon/getAddons', showMsg: false, showLoading: true },
  deleteAddon: { url: AppData.baseUrl + '/menu/addon/deleteAddons', showMsg: true, showLoading: false },

  // offers
  createOffer: { url: AppData.baseUrl + '/offer/createOffer', showMsg: true, showLoading: false },
  getOffers: { url: AppData.baseUrl + '/offer/getOffersList', showMsg: false, showLoading: false },
  deleteOffer: { url: AppData.baseUrl + '/offer/deleteOffer', showMsg: true, showLoading: false },

  //banners
  createBanner: { url: AppData.baseUrl + '/banner/', showMsg: true, showLoading: false },
  getBanners: { url: AppData.baseUrl + '/banner/', showMsg: false, showLoading: true },
  getBannerById: { url: AppData.baseUrl + '/banner/', showMsg: false, showLoading: false },
  changeBannerStatus: { url: AppData.baseUrl + '/banner/', showMsg: true, showLoading: false },
  updateBanner: { url: AppData.baseUrl + '/banner/', showMsg: false, showLoading: false },

  // Order Management
  changeStatus: { url: AppData.baseUrl + '/order/changeStatus', showMsg: true, showLoading: false },
  getOrders: { url: AppData.baseUrl + '/order/orderList', showMsg: false, showLoading: false },
  deliveryInfo: { url: AppData.baseUrl + '/order/deliveryInfo', showMsg: false, showLoading: false },

  // subscription
  getPlansList: { url: AppData.baseUrl + '/plans/getPlanList', showMsg: false, showLoading: false },
  createPlanPayment: { url: AppData.baseUrl + '/plans/createPlanPayment', showMsg: false, showLoading: false },
  checkPaymentStatus: { url: AppData.baseUrl + '/plans/checkPayment', showMsg: false, showLoading: false },
  // support
  getSupportCategories: { url: AppData.supportUrl + '/support/supportTicket/supportCategories/restPanel', showMsg: false, showLoading: false },
  createSupport: { url: AppData.supportUrl + '/support/supportTicket/supportMail', showMsg: true, showLoading: true },
  categoryReorder: { url: AppData.baseUrl + '/menu/category/reorderCat', showMsg: true, showLoading: false },

  // Report
  getOperationalReport: { url: AppData.baseUrl + '/report/getOperationalReport', showMsg: false, showLoading: false },
  getReport: { url: AppData.baseUrl + '/report/getReports', showMsg: false, showLoading: false },
  getOrderDetails: { url: AppData.baseUrl + '/report/getOrderDetails', showMsg: false, showLoading: false },
  downloadReport : { url: AppData.reportUrl + '/report/getOrderBreakup', showMsg: false, showLoading: false },
  // getOrderDetails : { url: AppData.reportUrl + '/restComm/orderDetails', showMsg: false },

  // Setup
  setupDetails: { url: AppData.baseUrl + '/outlet', showMsg: true, showLoading: false },

  // promotion
  promotionList: { url: AppData.baseUrl + '/promotion/getPromoList/', showMsg: false, showLoading: false },
  themeValidation: { url: AppData.baseUrl + '/promotion/validatePromo', showMsg: false, showLoading: false },
  themeEnroll: { url: AppData.baseUrl + '/promotion/enrollPromo', showMsg: true, showLoading: false },
  themeLeave: { url: AppData.baseUrl + '/promotion/rollback', showMsg: true, showLoading: false },
  promoItemList: { url: AppData.baseUrl + '/promotion/getPromoItemList', showMsg: false, showLoading: false },

  newOrderSubscription: { url: AppData.baseUrl + '/account/newOrderSubscription', showMsg: false, showLoading: false },
  getCurrentAddress: { url: AppData.googleMapUrl + '/maps/api/geocode/json', showMsg: false, showLoading: false },
  // ScratchCard Urls
  scratchCardToggle: { url: AppData.scratchCardUrl + '/toggle', showMsg: true, showLoading: true },
  scratchCardSetConfig: { url: AppData.scratchCardUrl + '/config', showMsg: true, showLoading: true },
  scratchCardGetConfig: { url: AppData.scratchCardUrl + '/config', showMsg: false, showLoading: false },

  // Engagament
  facebookUsers: { url: AppData.engagamentUrl + '/user/accounts', showMsg: false, showLoading: false },
  facebookLogin: { url: AppData.engagamentUrl + '/user/login', showMsg: false, showLoading: true },
  facebookPages: { url: AppData.engagamentUrl + '/page/list', showMsg: false, showLoading: true },
  facebookPost: { url: AppData.engagamentUrl + '/page/post', showMsg: true, showLoading: true },
  getFacebookPosts: { url: AppData.engagamentUrl + '/page/posts', showMsg: false, showLoading: true },
  createPostWithImg: { url: AppData.engagamentUrl + '/page/imagePost', showMsg: true, showLoading: true },
  deletefacebookPost: { url: AppData.engagamentUrl + '/page/post', showMsg: true, showLoading: true },
  facebookSingOut: { url: AppData.engagamentUrl + '/user/logout', showMsg: true, showLoading: true },
  checkFacebookLogin: { url: AppData.engagamentUrl + '/user/login', showMsg: false, showLoading: true },
  callToAction: { url: AppData.engagamentUrl + '/ads/supportedCallToActions', showMsg: false, showLoading: true },
  adPost: { url: AppData.engagamentUrl + '/ads', showMsg: true, showLoading: true },
  getAdPost: { url: AppData.engagamentUrl + '/ads', showMsg: false, showLoading: true },
  // Campaign
  createCampaign: { url: AppData.engagamentUrl + '/ads/campaign', showMsg: true, showLoading: true },
  getCampaignById: { url: AppData.engagamentUrl + '/ads/campaign', showMsg: false, showLoading: true },
  getCampaignTypeList: { url: AppData.engagamentUrl + '/ads/campaign/objectives', showMsg: false, showLoading: true },
  getCampaign: { url: AppData.engagamentUrl + '/ads/campaign', showMsg: false, showLoading: true },
  foodTags: { url: AppData.engagamentUrl + '/ads/adSet/interestTag', showMsg: false, showLoading: false },
  createUpdateAdSet: { url: AppData.engagamentUrl + '/ads/adSet', showMsg: true, showLoading: true },
  getAdSetById: { url: AppData.engagamentUrl + '/ads/adSet', showMsg: false, showLoading: true },

  // Manage Campaign
  getCampaigns: { url: AppData.engagamentUrl + '/ads/campaigns/list', showMsg: false, showLoading: true },
  deleteCampaign: { url: AppData.engagamentUrl + '/ads/campaign', showMsg: true, showLoading: true },
  updateCampaignStatus: { url: AppData.engagamentUrl + '/ads/campaign', showMsg: false, showLoading: true },
  createPixel: { url: AppData.engagamentUrl + '/ads/pixel', showMsg: false, showLoading: true },
  getPixelList: { url: AppData.engagamentUrl + '/ads/pixel/list', showMsg: false, showLoading: true },


  // Common
  getCuisinesAndCities: { url: AppData.supportUrl + '/data', showMsg: false, showLoading: false },

  //gallery
  uploadGalleryImg: { url: AppData.gMediaUrl + '/gallery/img', showMsg: false, showLoading: true },
  getGalleryImages: { url: AppData.gMediaUrl + '/gallery/search', showMsg: false, showLoading: false },
  getAllGalleryImages: { url: AppData.gMediaUrl + '/gallery/imgs', showMsg: false, showLoading: false },
  deleteGalleryImages: { url: AppData.gMediaUrl + '/gallery/deleteImg', showMsg: false, showLoading: false },

  //tracking
  Map: { url:'https://api.common.laalsa.com/support' + '/support/crm/maps', showMsg: false },
  DeliveryPartnerList: { url: AppData.baseUrl + 'support/crm/getDeliveryParteners', showMsg: false },
  DeliveryStatus: { url: AppData.baseUrl + 'support/crm/getCurrentDeliveryStatus', showMsg: false },

  // POS orders
  getCart: { url: AppData.baseUrl + '/pos/restCart/cart', showMsg: false, showLoading: true },
  addItemToCart: { url: AppData.baseUrl + '/pos/restCart/cart/add', showMsg: false, showLoading: true },
  applyCoupon: { url: AppData.baseUrl + '/pos/restCart/cart/coupon', showMsg: false, showLoading: true },
  applySelfPickup: { url: AppData.baseUrl + 'support/crm/getCurrentDeliveryStatus', showMsg: false, showLoading: true },
  updateAddonVariant: { url: AppData.baseUrl + '/pos/restCart/cart/item', showMsg: false, showLoading: true },
  cartId: { url: AppData.baseUrl + '/pos/restCart/cart', showMsg: false, showLoading: false },
  changeItemCount: { url: AppData.baseUrl + '/pos/restCart/cart/updateItemQuantity', showMsg: false, showLoading: false },
  getCustomerDetails: { url: AppData.baseUrl + '/pos/getCustomer', showMsg: false, showLoading: true },
  updateCustomerDetails: { url: AppData.baseUrl + '/pos/updateCustomer', showMsg: false, showLoading: true },
  preOrderCheckout: { url: AppData.baseUrl + '/pos/restCart/cart/validate', showMsg: false, showLoading: true },
  createPayment: { url: AppData.paymentUrl + '/createPayment', showMsg: false, showLoading: true },
  sendPaymentLink: { url: AppData.baseUrl + '/pos/order/pay-online', showMsg: false, showLoading: true },
  outletNearByCustomer: { url: AppData.baseUrl + '/pos/outlets/near', showMsg: false, showLoading: true },
  directDelivery:{url:AppData.baseUrl + '/pos/newOrder',showMsg: false, showLoading: false},
  // pre order checkout
  placeOrder: { url: AppData.baseUrl + '/pos/newOrder', showMsg: false, showLoading: true },
  postCheckout: { url: AppData.baseUrl + '/order/postCheckout', showMsg: false, showLoading: true },
  getAllCoupon: { url: AppData.baseUrl + '/coupon/couponList', showMsg: false, showLoading: true },
  removeCoupon: { url: AppData.baseUrl + '/cart/coupon', showMsg: false, showLoading: true },
  clearPlate: { url: AppData.baseUrl + '/pos/restCart/cart/clear', showMsg: false, showLoading: true },
  noSelfPickup: { url: AppData.baseUrl + '/cart/noSelfPickup', showMsg: false, showLoading: true },
  selfPickup: { url: AppData.baseUrl + '/cart/selfPickup', showMsg: false, showLoading: true },

  //
  wallet:{ url: AppData.baseUrl + '/pos/outlet/wallet', showMsg: false, showLoading: true},
  getAllItemsByCategory: { url: AppData.baseUrl + '/menu/item/getAllItemsByCategory', showMsg: false, showLoading: true },
  createCart:{ url: AppData.baseUrl + '/pos/restCart/cart/create',showMsg: false, showLoading: true},
  billInfo:{ url: AppData.baseUrl + '/pos/restCart/cart/validate',showMsg: false, showLoading: true},
  crmList: { url: AppData.baseUrl + '/crm/360/profiles', showMsg: false, showLoading: true },
  crmImportFile: { url: AppData.baseUrl + '/crm/360/profiles/exl-import', showMsg: false, showLoading: true },
  getAllOutlets:{url:'https://api.common.laalsa.com/ypanel' + '/salesRestaurant/migrate/fetchOutlets', showMsg: false, showLoading: false },
  migrateOutlet:{url:'https://api.common.laalsa.com/ypanel' + '/salesRestaurant/migrate/migrateOutlet',showMsg: false, showLoading: false },

  //CSV UPLOAD
  csvUpload: { url: AppData.baseUrl + '/pos/csv-orders', showMsg: false, showLoading: false }


};

export class RequestUrl {
  static get(key: URL_KEYS): any {
    return UrlMapping[key];
  }
}

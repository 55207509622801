export const environment = {
    production: false,
    baseUrl: 'https://common.laalsa.com/restaurantOrdering',
    // baseUrl: 'https://api.dev.yumzy.ai/restorder',
    commonBaseUrl: 'https://common.laalsa.com/restaurantOrdering',
    reportUrl: 'https://common.laalsa.com/reports',
    supportUrl: 'https://common.laalsa.com/support', // QA
    socketUrl: 'https://common.laalsa.com',
    scratchCardUrl:'https://common.laalsa.com/restaurantOrdering/sc/process',
    engagamentUrl: 'https://common.laalsa.com/restaurantOrdering/marketing/fb',
    fssaiBaseUrl: 'https://fssai.laalsa.com',
    gstInCheckUrl: 'https://biz.coniferlabs.in:4001',
    ifscNumberUrl: 'http://api.techm.co.in',
    termsConditionsUrl: 'https://laalsadev.sgp1.digitaloceanspaces.com/common_documents/Terms&Conditions.pdf',
    notificationSound: 'https://laalsadev.sgp1.digitaloceanspaces.com/common_documents/alarm-frenzy.mp3',
    googleMapUrl: 'https://maps.googleapis.com',
    mapApiKey: 'AIzaSyB0jz-2g15TatkSFyTK0Qxl0LPv1tErScU',
    // facebookAppId: '1559284744206810', //Yumzy
    facebookAppId: '316579750093518', //YumzyX
    gMediaUrl: '',
    paymentUrl: 'https://api.common.laalsa.com/paymentco',
  };
  
import { Router } from '@angular/router';
import { Injectable } from '@angular/core';
import {
  HttpInterceptor,
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpErrorResponse,
} from '@angular/common/http';

import { Observable, throwError } from 'rxjs';
import { map, catchError, tap } from 'rxjs/operators';
import { ToastService } from '../services/toast.service';
import { ROUTES_STR } from 'src/app/core/constants/app-constants';
import { LogoutService } from '../services/logout.service';

@Injectable()
export class ResponseInterceptor implements HttpInterceptor {
  /**
   *
   * @param req
   * @param next
   * expected output from api : {message:"", data:[] | {} }
   */

    constructor(private toasterService: ToastService,
      private router: Router,
      private logoutService: LogoutService
  ) { }
 

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    console.log('called response interceptor', req);
    const excludedUrl = ['/restaurantOrdering/pos/csv-orders'];
    const isExcluded = excludedUrl.some(url => req.url.includes(url));
    return next.handle(req).pipe(
      map((event: HttpEvent<any>) => {
        return event;
      }),
      catchError((err: any, caught) => {
        if (err instanceof HttpErrorResponse) {
          if (Math.floor(err.status / 100) === 4) {
            // handle 400 errors
            // todo load a toast here with err.message - received from server
            if (err.status === 403) {
              //  Handling the GSTN API response
              if (err && err.error.message) {
                this.toasterService.presentToast(err.error.message, 'Error', 'error');
              } else {
                this.toasterService.presentToast(err.error, 'Error', 'error');
              }  

              if (err.error.code === 'LOGGED_OUT') {
                this.logoutService.logout();
                this.router.navigate([ROUTES_STR.login]);
                // this.toasterService.presentToast('Login to continue', 'Error', "error");
              }

            } else if (err.status === 401) {
              console.log('session Expired');
              this.router.navigate([ROUTES_STR.login]);
              // this.toasterService.presentToast(err.error.message, 'Error', 'error');
            } else if (err.error.code === 'TOKEN_EXPIRED') {
              this.router.navigate([ROUTES_STR.login]);
              this.toasterService.presentToast('Session Expires!!', '', 'error');
            }
            
            else {
              if (!isExcluded) {
                this.toasterService.presentToast(err.error.message, 'Error', 'error');
              }
              console.log('err.error =', err.error, ';');
            }
          }
          if (Math.floor(err.status / 100) === 5) {
            console.log('err.error =', err.error, ';');
            const responseError = err.error;
            if (!isExcluded) {
              this.toasterService.presentToast(err.error.message, '', 'error');
            }
          }
          console.log(err);
          return throwError(err);
        }
      })
    );
  }
}

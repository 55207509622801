import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard, LoginGuard } from './shared';


const routes: Routes = [
  { path: '', redirectTo: 'login', pathMatch: 'full' },
  {
    path: 'login',
    canActivate :[LoginGuard],
    loadChildren: () => import('./module/login/login.module').then(m => m.LoginModule),
  },
  {
    path: 'signup',
    canActivate :[LoginGuard],
    loadChildren: () => import('./module/sign-up/sign-up.module').then(m => m.SignUpModule),
  },
  {
    path: 'panel',
    canLoad: [AuthGuard],
    loadChildren: () => import('./module/home/home.module').then(m => m.HomeModule),
  },
  {
    path: 'mobile-panel',
    canLoad: [AuthGuard],
    loadChildren: () => import('./module/mobile-home/mobile-home.module').then(m => m.MobileHomeModule),
  },

  // {
  //   path: 'no-internet',
  //   canActivate :[LoginGuard],
  //   loadChildren: () => import('./module/no-internet/no-internet.module').then(m => m.NoInternetModule),
  // },

  { path: '**', redirectTo: 'login' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
  providers:[LoginGuard]
})
export class AppRoutingModule {
  constructor() {

  }
}
